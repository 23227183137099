import React from "react";
import DataTable from "react-data-table-component";
import "../../assets/css/reusable/datatable.css";
import NoData from "../NoData";
import NoDataSearchComponent from "../noDataSearchComponent";

const Datatable = (props: any) => {
  const paginationComponentOptions = {
    rowsPerPageText: "Showing ",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,

    // selectAllRowsItemText: 'Regsahgsjgajgjgahsgjagshgajhah',
  };

  return (
    <>
      {props.data?.length > 0 ? (
        <DataTable
          columns={props.columns}
          data={props.data}
          pagination
          // paginationServer
          striped
          highlightOnHover
          paginationTotalRows={props.total}
          paginationComponentOptions={paginationComponentOptions}
          selectableRows={props?.selectableRows ? true : false}
          onSelectedRowsChange={props?.handleSelectedRowsChange}
          persistTableHead
          className={props?.className}
          // onChangeRowsPerPage={countPerPage => setcountPerPage(countPerPage)}
          // onChangePage={page => setPage(page)}
          // paginationRowsPerPageOptions={[10, 25, 50, 100, -1]}
          // subHeader
          // subHeaderComponent={subHeaderComponentMemo}
          // paginationResetDefaultPage={resetPaginationToggle}
          // selectableRows
          // persistTableHead
          // onSelectedRowsChange={()=>{clickRow()}}
        />
      ) : (
        <DataTable
          columns={props.columns}
          data={[]} // Passing an empty array
          noDataComponent={
            props?.search?.length < 0 ? (
              <NoData title={props.title} subtitle={props.subtitle} />
            ) : (
              <NoDataSearchComponent />
            )
          }
          persistTableHead
        />
      )}
    </>
  );
};

export default Datatable;
