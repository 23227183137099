import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Marker } from 'react-map-gl';
import Pin from '../../views/pages/Map/Pin';
import { fetchCoordinates } from '../../redux/slice/coordinate';
import { selectedComponent } from '../../redux/slice/Map';
import { useLocation } from 'react-router-dom';
import { updateMonitoring } from '../../redux/slice/monitoring';
import { fetchHorizontalTab } from '../../redux/slice/checklist';

const MapList = () => {
    const  location = useLocation();
    const [markerArray, setMarkerArray]: any[] = React.useState([])
    const coordinate = useAppSelector((state) => state.coordinate);
    const components = useAppSelector((state) => state.components);
    const monitoring = useAppSelector((state) => state.monitoring);
    const dispatch = useAppDispatch()
    React.useEffect(()=>{
      if(components.selectedComponent?.id){
        dispatch(updateMonitoring({
          ...monitoring.updatedData,
          coordinate_id: components.selectedComponent?.id
        }))
      }
      
    },[components.selectedComponent?.id])
    

    React.useEffect(() => {
        dispatch(fetchCoordinates(location?.state?.projectData?.project_id))
      },[])

    React.useEffect(() => {
      if(coordinate.coordinates.data.data){
        let data2= coordinate.coordinates.data.data.filter((e:any)=> {
          if(e.DesignerChecklistStatus === true ){
            return e
          }
         }
        )
          if(data2){
          setMarkerArray(data2)
          }else{
            setMarkerArray([])
          }
      }
       
      },[coordinate.coordinates.data.data])
      

      const handleNameClick = (event: any, data: any) => {
        
        dispatch(selectedComponent({ item: data, current: false }))
        
        dispatch(fetchHorizontalTab(data.id))
        
        
      }
    
    return (
        <>
        {markerArray &&
              markerArray.map((e: { longitude: number; latitude: number; name: string; icon: any }) => {
                return (


                  <span className='hover:cursor-pointer'
                  // onContextMenu={(any:any)=>{console.log(any.type)}}
                  >

                    <Marker

                      // onClick={()=>{markerClick(e)}}
                      longitude={e.longitude}
                      latitude={e.latitude}
                      // anchor="bottom"
                    //   draggable
                    //   onDragStart={onMarkerDragStart}
                    //   onDrag={(event) => { onMarkerDrag(event, e) }}
                    //   onDragEnd={(event) => { onMarkerDragEnd(event, e) }}
                    >
                      <Pin dark={true} icon={e} size={200} handleNameClick={handleNameClick} />
                    </Marker>
                  </span>
                )
              })
            }
        </>
    )
}

export default MapList;
