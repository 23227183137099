import logo from "../../assets/images/logo.svg";
import small_logo from "../../assets/images/small_logo.svg";
import profile_img from "../../assets/images/profile.png";
import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import "../../assets/css/layout/header.css";
import { fetchProfile } from "../../redux/slice/Profile";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createClient } from "@supabase/supabase-js";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchAssignPermission,
  fetchPermission,
} from "../../redux/slice/Permission";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";

const supabase = createClient(
  "https://reeqqghunqccmwqdgfma.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJlZXFxZ2h1bnFjY213cWRnZm1hIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTE3MDk3MjksImV4cCI6MjAyNzI4NTcyOX0.PyKYpOk3hO9rH6xFY9HdvrtiKtEBsJUw6EEn_PzmZ60"
);

const Header = ({ collapsed, setCollapsed }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [profiles, setProfiles]: any = useState();
  const profile = useAppSelector((state) => state.profile);
  const { permission, Currentpermission } = useAppSelector(
    (state) => state.permission
  );
  const location = useLocation();

  useEffect(() => {
    if (profile?.profile?.data?.role_id) {
      dispatch(
        fetchAssignPermission({
          apiParam: { role_id: profile?.profile?.data?.role_id, pass: 1 },
        })
      );
    }
  }, [profile?.profile?.data?.role_id]);

  useEffect(() => {
    if (profiles?.user?.email) {
      dispatch(fetchProfile({ email: profiles?.user?.email }));
    }
  }, [profiles]);

  async function signOut() {
    try {
      await supabase.auth.signOut();
      console.log("logout");
      localStorage.removeItem("sb-reeqqghunqccmwqdgfma-auth-token");
      navigate("/");
    } catch (error) {
      // console.error('Error signing out:', error?.message);
    }
  }

  useEffect(() => {
    let localStorageData: any = localStorage.getItem(
      "sb-reeqqghunqccmwqdgfma-auth-token"
    );
    setProfiles(JSON.parse(localStorageData));
  }, [localStorage]);

  return (
    <header className="bg-white py-2 px-4 w-full">
      <div
        className={`  flex  items-center ${
          location.pathname.includes("Screen-Monitoring") ||
          location.pathname.includes("Screen_monitoring")
            ? ""
            : "justify-between"
        }`}
      >
        <h1
          className={`text-white text-3xl font-semibold  ${
            location.pathname.includes("Screen-Monitoring") ||
            location.pathname.includes("Screen_monitoring")
              ? "flex justify-start w-2/12 "
              : ""
          }}`}
        >
          <img
            src={collapsed ? small_logo : logo}
            className="h-8 mt-1"
            alt=""
            onClick={() => {
              navigate("/");
            }}
          />
        </h1>

        <div
          className={`border rounded-full flex items-center  ${
            location.pathname.includes("Screen-Monitoring") ||
            location.pathname.includes("Screen_monitoring")
              ? " sm:ml-4 md:ml-4 lg:ml-4 xl:ml-4 2xl:ml-4 absolute left-56"
              : " absolute left-60"
          }`}
        >
          {collapsed ? (
            <FontAwesomeIcon
              icon={faAngleRight}
              className="text-[#377DFF] px-2 py-[6px] cursor-pointer"
              size="xs"
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="text-[#377DFF] px-2 py-[6px] cursor-pointer"
              size="xs"
              onClick={() => setCollapsed(!collapsed)}
            />
          )}
        </div>
        <div
          className={`${
            location.pathname.includes("Screen-Monitoring") ||
            location.pathname.includes("Screen_monitoring")
              ? "flex justify-between w-full"
              : ""
          }`}
        >
          {location.pathname.includes("Screen-Monitoring") ||
          location.pathname.includes("Screen_monitoring") ? (
            <div className="flex  custom-margin ">
              <div className="relative ml-1 xl:ml-12 md:ml-12 my-2">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="text-[#2C2F34] absolute top-3 left-3 text-sm"
                />
                <input
                  type="search"
                  placeholder="Search"
                  className="border border-[#EFF1F7] pl-9 placeholder:text-[#2C2F34] placeholder:text-[14px] py-[6px] rounded-[28px]"
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <nav className="flex space-x-4">
            <div className="text-black flex items-center">
              {/* <div className='relative grid content-end '>
                                <FontAwesomeIcon icon={faBell} size='xl' className='mt-[8px]' />
                                <div className='bg-[#FF4C4C] rounded-[100%] w-5 h-5 text-[#FFF] absolute -top-[3px] left-2 text-xs  flex items-center justify-center'>20</div>
                            </div> */}
            </div>
            <div className="text-black">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 items-center bg-white text-sm font-semibold text-gray-900  hover:bg-gray-50">
                    <div className="flex items-center justify-start mt-2">
                      <img src={profile_img} alt="" className="mr-3" />
                      <div className="text-left">
                        <h6 className="font-bold text-[14px] capitalize">
                          {profile?.profile?.data?.first_name}{" "}
                          {profile?.profile?.data?.last_name}{" "}
                        </h6>
                        <span className="font-normal text-slate-400 text-[12px]">
                          {profile?.profile?.data?.role}
                        </span>
                      </div>
                    </div>
                    {/* <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon> */}
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-[60] mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {/* <form method="POST" action="#"> */}
                      <Menu.Item>
                        <button
                          type="submit"
                          onClick={() => {
                            signOut();
                          }}
                          className="block w-full px-4 py-2 text-left text-sm"
                        >
                          Sign out
                        </button>
                      </Menu.Item>
                      {/* </form> */}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
