import img from "../../assets/images/NoResultFound.png"
import "../../assets/css/reusable/index.css"
import { useLocation } from "react-router-dom";

const NoDataSearchComponent = () => {
  const location = useLocation();

  return (

    <div className={`${ location.pathname === '/Checklist' ?'w-full flex items-center justify-center flex-col noDataSection mt-12 h-[57vh]':'noDataSection mt-12 h-[57vh]'} `}>

      <img src={img} alt=""/>
      <div className='flex flex-col text-center gap-4'>
        <h2 className='text-center  text-xl not-italic font-semibold leading-[19px] heading-hidden '>No Data Available</h2>
        {
          location.pathname === '/Checklist' ? null : (<h2 className='userCreated text-center text-[#132144] text-xl not-italic font-semibold leading-[19px]'>No Results Found</h2>)
        }


        {
          location.pathname === '/Checklist' ? (
          <>
          <h2 className='userCreated text-center text-[#132144] text-xl not-italic font-semibold leading-[19px]'>No Results Found</h2>
          <p className='userCreatedP text-[#71869D] text-sm not-italic font-normal leading-7'>
            Sorry, we couldn't find any items matching your search. </p></>) : (<p className='userCreatedP text-[#71869D] text-sm not-italic font-normal leading-7'>Sorry, we couldn’t find any matches for your search. <br />
              Please check your spelling or try using a different keyword. </p>)
        }



      </div>
      <div>

      </div>
    </div>
  )
};

export default NoDataSearchComponent;
