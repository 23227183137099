import React, { useEffect, useState } from 'react'

import Map, { Layer, Marker, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import type { LngLat, MarkerDragEvent } from 'react-map-gl';
import Pin from '../../views/pages/Map/Pin';
import Loading from '../../views/pages/Loading';
import { useAppDispatch, useAppSelector } from '../../hooks';

import '../../assets/css/reusable/screenmonitoring.css'

import MapviewSM from '../MapViewSM';
import Timeline from '../TimeLine';
import MapList from './MapList';
import Gpt from '../GptScreen';
import Calendar from '../Calender';
import { FeatureCollection, LineString } from 'geojson';
import { monitoringScreenWaterStrech } from '../../redux/slice/monitoring';
import { useLocation } from 'react-router-dom';
import { fetchNetwork } from '../../redux/slice/Map';
import { MAPBOX_TOKEN } from '../../config';
const initialViewState = {
  longitude: 73.90959193240296,
  latitude: 18.580587992841764,
  zoom: 12
};





const MapDashboard = ({ activeTab }: any) => {
  const disptach = useAppDispatch()
  const components = useAppSelector((state) => state.components);
  const coordinate = useAppSelector((state) => state.coordinate);
  const monitoring = useAppSelector((state) => state.monitoring);
  const [mapStyle, setMapStyle] = React.useState("mapbox://styles/mapbox/dark-v11");
  const location = useLocation();

  const [markerArray, setMarkerArray]: any[] = React.useState([]);
  const [colorCodeArray, setcolorCodeArray]: any[] = React.useState([]);
  const [viewport, setViewport] = useState(initialViewState);
  const [coordinatesArray, setcoordinatesArray]: any[] = React.useState([]);
  const [layer, setLayer]: any = React.useState(null);
  const [networkdata, setNetwork]: any = React.useState({})
  const [tileset, setTileset] = React.useState(null);


  //   React.useEffect(() => setMarkerArray(markersArray), [])
  const [events, logEvents] = React.useState<Record<string, LngLat>>({});
  const onMarkerDragStart = React.useCallback((event: any) => {
    logEvents(_events => ({ ..._events, onDragStart: event.lngLat }));
  }, []);

  const onMarkerDrag = React.useCallback((event: any, e: any) => {
    logEvents(_events => ({ ..._events, onDrag: event.lngLat }));
    setMarkerArray((prevMarkers: any[]) => {
      return prevMarkers.map((marker: { id: any; }) => {
        if (marker.id === e.id) {
          // Merge existing marker data with new data
          return {
            ...marker, longitude: event.lngLat.lng,
            latitude: event.lngLat.lat
          };
        }
        // console.log(marker)
        return marker;

      });
    });
    // console.log(2,event)
    // dispatch(formPopup(false))

    // setMarker({
    //   longitude: event.lngLat.lng,
    //   latitude: event.lngLat.lat
    // });
  }, []);
  const onMarkerDragEnd = React.useCallback((event: any, e: any) => {
    logEvents(_events => ({ ..._events, onDragEnd: event.lngLat }));
  }, []);

  useEffect(() => {
    if (monitoring?.waterStrech?.data?.WaterStrechColorCode) {
      let outputArray = monitoring?.waterStrech?.data?.WaterStrechColorCode.flatMap((item: { split: (arg0: string) => { (): any; new(): any; map: { (arg0: (subItem: any) => any): [any, any]; new(): any; }; }; }) => {
        // Split each string by the comma and trim spaces
        let [num, color] = item.split(',').map((subItem: string) => subItem.trim());

        // Parse the numeric value and remove single quotes from the color value
        return [parseFloat(num), color.replace(/'/g, '')];
      });

      setcolorCodeArray(outputArray)


    }

  }, [monitoring?.waterStrech?.data?.WaterStrechColorCode])
  // const colorCodeArray = [
  //   0, 'blue',
  //   0.1, 'royalblue',
  //   0.3, 'cyan',
  //   0.5, 'lime',
  //   0.7, 'yellow',
  //   1, 'red'
  // ];


  useEffect(() => {
    let coData = coordinate?.coordinates?.data?.data
    let mergedArray = coData?.map((item: { id: string | number; }) => {
      if (monitoring?.waterStrech?.data?.WQI[item?.id]) {
        return { ...item, ...monitoring.waterStrech.data.WQI[item?.id] };
      }
      return item;
    });
    //console.log("coord",mergedArray,monitoring?.waterStrech?.data?.WQI);
    setcoordinatesArray(mergedArray)
    //  if(monitoring?.waterStrech?.data?.WQI){
    //   colorCodeArray = Object.values(monitoring?.waterStrech?.data?.WQI).map((entry:any, index) => {
    //     return `${index}, "${entry?.fuzzy_logic_color_code}"`;
    // }).join(', ');
    //  }

    //   const fuzzyLogicColorCodes = Object.values(monitoring?.waterStrech?.data?.WQI).map((entry:any, index) => {
    //     return `${index}, "${entry?.fuzzy_logic_color_code}"`;
    // });
    // colorCodeArray = `[${fuzzyLogicColorCodes.join(', ')}]` || [];
  }, [coordinate?.coordinates?.data, monitoring?.waterStrech?.data?.WQI]);







  useEffect(() => {
    setMapStyle(activeTab == 'tab1' ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11")
  }, [activeTab]);


  const handleZoomIn = () => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      zoom: prevViewport.zoom + 1
    }));
  };

  const handleZoomOut = () => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      zoom: prevViewport.zoom - 1
    }));
  };

  const [nearestPointCoords, setNearestPointCoords] = useState<Array<any>>([]);
  const [lines, setLines] = useState<Array<FeatureCollection<LineString>>>([]);
  //console.log(lines);


  useEffect(() => {
    const fetchNearestPoints = async () => {
      try {
        const nearestPointCoords: Array<any> = [];
        for (const element of coordinatesArray) {
          const { latitude, longitude } = element;
          const nearestPoint = await findNearestPoint(latitude, longitude);
          if (nearestPoint) {
            nearestPointCoords.push([nearestPoint.lon, nearestPoint.lat]);
          }
        }
        setNearestPointCoords(nearestPointCoords);
        await drawLinesSequentially(nearestPointCoords);
      } catch (error) {
        console.error("Error in fetching waterway data:", error);
      }
    };

    fetchNearestPoints();
  }, [coordinatesArray]);

  const findNearestPoint = async (lat: number, lng: number) => {
    const overpassUrl = `https://overpass-api.de/api/interpreter?data=[out:json];way[waterway](around:10000,${lat},${lng});out geom;`;
    const response = await fetch(overpassUrl);
    const data = await response.json();

    if (data.elements && data.elements.length > 0) {
      let nearestPoint: any = null;
      let minDistance = Infinity;

      data.elements.forEach((element: any) => {
        if (element.geometry && element.geometry.length > 0) {
          element.geometry.forEach((point: any) => {
            const distance = calculateDistance(lat, lng, point.lat, point.lon);
            if (distance < minDistance) {
              minDistance = distance;
              nearestPoint = point;
            }
          });
        }
      });

      return nearestPoint;
    } else {
      console.log("No nearby waterways found.");
      return null;
    }
  };

  const drawLinesSequentially = async (coords: Array<[number, number]>) => {
    for (let i = 0; i < coords.length - 1; i++) {
      await fetchAndDrawLine(coords[i], coords[i + 1]);
    }
  };

  const fetchAndDrawLine = async (point1: [number, number], point2: [number, number]) => {
    const bbox = [
      Math.min(point1[1], point2[1]),
      Math.min(point1[0], point2[0]),
      Math.max(point1[1], point2[1]),
      Math.max(point1[0], point2[0])
    ];
    const overpassUrl = `https://overpass-api.de/api/interpreter?data=[out:json];way[waterway](${bbox.join(',')});out geom;`;

    const response = await fetch(overpassUrl);
    const data = await response.json();

    if (data.elements && data.elements.length > 0) {
      const waterwayCoordinatesArray: [number, number][] = [];

      data.elements.forEach((element: any) => {
        if (element.geometry && element.geometry.length > 0) {
          element.geometry.forEach((point: any) => {
            waterwayCoordinatesArray.push([point.lon, point.lat]);
          });
        }
      });

      const point1Index = waterwayCoordinatesArray.findIndex(coord => coord[0] === point1[0] && coord[1] === point1[1]);
      const point2Index = waterwayCoordinatesArray.findIndex(coord => coord[0] === point2[0] && coord[1] === point2[1]);

      if (point1Index > point2Index) {
        waterwayCoordinatesArray.reverse();
      }

      const valuesBetweenPoints = waterwayCoordinatesArray.slice(Math.min(point1Index, point2Index), Math.max(point1Index, point2Index) + 1);

      const geojson: FeatureCollection<LineString> = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: valuesBetweenPoints
          }
        }]
      };

      setLines(prevLines => [...prevLines, geojson]);
      // setLines(prevLines => [...prevLines, { geojson:geojson }]);
    } else {
      console.log("No waterways found in the bounding box.");
    }
  };

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371e3;
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  useEffect(() => {
    if (location.state.projectData.project_id && monitoring.updatedData) {
      let data = {
        ...monitoring.updatedData,
        project_id: location.state.projectData.project_id
      }
      disptach(monitoringScreenWaterStrech(data))
    }

  }, [monitoring.updatedData, location.state.projectData.project_id])
  const updateTilesetAndAddLayer = (obj: any) => {
    setTileset(obj.tileset);
  };

  React.useEffect(() => {
    // alert(1)
    if (networkdata?.upload_response) {
      // alert(2)
      // updateTileset(networkdata.upload_response.tileset);
      let data1: any = JSON.stringify(networkdata.upload_response)

      let data2: any = JSON.parse(data1)

      let obj2 = JSON.parse(data2)
      updateTilesetAndAddLayer(obj2);
      setLayer(obj2)
    }
  }, [networkdata?.upload_response])

  React.useEffect(() => {
    if (components?.network?.data?.data) {
      setNetwork(components?.network?.data?.data)
    }
  }, [components?.network?.data?.data])

  React.useEffect(() => {
    if (location.state.projectData?.project_id) {
      disptach(fetchNetwork({ "project_id": location.state.projectData?.project_id }))
    }
  }, [location.state.projectData?.project_id])

  const displayShapefileOnMap = (response: any) => {
    const tileset = response.tileset;
    const name = response.name;
    const shapefileSource = {
      type: 'vector',
      url: `mapbox://${tileset}`
    };


    return (
      <Source id="shapefile-source" type="vector" url={shapefileSource.url}>
        <Layer
          id="shapefile-layer-network"
          type="fill"
          source="shapefile-source"
          source-layer={name}
          paint={{
            'fill-color': '#088',
            'fill-opacity': 0.8
          }}
        />
      </Source>
    );
  };
  return (
    <>

      {
        activeTab == 'tab1' ? (
          <div className='h-[98vh] '>
            <Map
              {...viewport}
              mapboxAccessToken= {MAPBOX_TOKEN}
              initialViewState={initialViewState}
              onMove={(evt) => setViewport(evt.viewState)}
              //   onClick={handleMapClick}
              // onViewportChange={handleViewportChange}
              //style={{position:"absolute"}}

              style={{ width: '100vw', height: '92.5%' }}
              mapStyle={mapStyle}


            >
              {layer && initialViewState.latitude && initialViewState.longitude && displayShapefileOnMap(layer)}
              {
                activeTab == 'tab1' ? (
                  <>
                    <MapviewSM />
                    <MapList />
                    <div className='absolute right-6 z-10 top-[27rem] flex flex-col bg-[#44444F] rounded-md'>
                      <button onClick={handleZoomIn} className='text-white font-semibold text-xl px-3 py-2'>+</button>
                      <button onClick={handleZoomOut} className='text-white font-semibold text-xl px-3 py-2'>-</button>
                    </div>
                    <div className='absolute bottom-16 left-[44rem] z-10'>
                      <Calendar />
                    </div>
                    <div className='w-full flex absolute -bottom-1 z-10'>
                      <Timeline />
                    </div>
                    <div>
                      <Gpt activeTab={activeTab} />
                    </div>
                    {lines.map((line, index) => (
                      <Source key={index} type="geojson" data={line} lineMetrics={true}>

                        <Layer
                          id={`line-${index}`}
                          type="line"
                          paint={{
                            'line-color': 'red',
                            'line-width': 10,
                            'line-gradient': [
                              'interpolate',
                              ['linear'],
                              ['line-progress'],
                              ...colorCodeArray
                            ]
                          }}
                          layout={{
                            'line-cap': 'round',
                            'line-join': 'round'
                          }}
                        />
                      </Source>
                    ))}
                  </>

                ) : (<></>)
              }

            </Map>
          </div>) : (
          // <div className='h-[48vh] w-[47.5vw]'>
          <Map
            {...viewport}
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={initialViewState}
            onMove={(evt) => setViewport(evt.viewState)}
            //   onClick={handleMapClick}
            // onViewportChange={handleViewportChange}
            //style={{position:"absolute"}}
            style={{ width: '46.9vw', height: '42vh' }}
            mapStyle={mapStyle}


          >
            <div className='absolute z-50 right-2 bottom-8 flex flex-col bg-[#c0c0c3] rounded-md'>
              <button onClick={handleZoomIn} className='text-white font-semibold text-xl px-3 py-2'>+</button>
              <button onClick={handleZoomOut} className='text-white font-semibold text-xl px-3 py-2'>-</button>
            </div>
          </Map>
          // </div>
        )
      }
    </>


  )
}

export default MapDashboard;
