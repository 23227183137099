import React, { useEffect, useState } from 'react'
import PageTitle from '../PageTitle'
import { Menu, Transition } from '@headlessui/react'
import Datatable from "../DataTable"
import { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilterList } from '@fortawesome/pro-regular-svg-icons'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons'
// import '../../assets/css/reusable/screenmonitoring.css'
import { useAppSelector } from '../../hooks'

const  CodeCompliance = () => {
    const monitoring = useAppSelector((state) => state.monitoring);
    const [tableData,setTableData] = useState([]);


    useEffect(()=>{
      if(monitoring?.screenStats?.data?.getCodeCompliance?.original?.data){
      setTableData(monitoring?.screenStats?.data.getCodeCompliance?.original?.data)
      }
    },[monitoring?.screenStats?.data?.getCodeCompliance?.original?.data])
  const columns = [
    {
      name: "Parameter",
        selector: (row:any) => row.parameter_name,
      // cell: (row: any) => <span>
      //   {row.id}
      // </span>,
      // sortable: true,
    },
    {
      name: "Unit",
      selector: (row: any) => row.unit,
      cell: (row: any) => <span>
        {row.unit}
      </span>,
    },
    {
      name: "Obs. Value",
      selector: (row: any) => row.observation,
      cell: (row: any) => <div className='text-center'>
      {row.observation}
    </div>,
    },
    {
     
      name: <div className='flex items-center'>BIS <span className='ml-1 font-bold text-[#FFF] text-xs not-italic leading-3'>60% </span></div>,
      selector: (row: any) => row.BIS,
      cell: (row: any) => <div className='text-center'>
      {row.BIS}
    </div>,
    },
    {
      name: <div className='flex items-center'>WHO <span className='ml-1 font-bold text-[#FFF] text-xs not-italic leading-3'>30% </span></div>,
      selector: (row: any) => row.WHO,
      cell: (row: any) => <div className='text-center'>
      {row.WHO}
    </div>,
      
    },
    {
      name: <div className='flex items-center'>USEPA <span className='ml-1 font-bold text-[#FFF] text-xs not-italic leading-3'>30% </span></div>,
      selector: (row: any) => row.EPA,
      cell: (row: any) => <div className='text-center'>
      {row.EPA}
    </div>,
    },
    {
      name: <div className='flex items-center'>R&D <span className='ml-1 font-bold text-[#FFF] text-xs not-italic leading-3'>30% </span></div>,
      selector: (row: any) => `${row.rd}`,
      cell: (row: any) => <div className='text-center'>
      {row.rd}
    </div>,
      

    },
  ];

  
  return (
    <div className='flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-4 pt-2 rounded-[7.452px]  code-compliance h-[254px] bg-[#222230] border-[#222230] text-[#FFF]'>
      <div className='flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full border-b border-[#4A4A68] items-center pb-4 mt-2 h-[28px]'>
        <div className="w-1/2 ">
    
        <h1 className="">Code Compliance</h1>
          {/* <PageTitle title="Code Compliance"></PageTitle> */}
        </div>
        <div className="w-1/2 flex flex-wrap justify-end items-center">
          <div className='flex items-center px-2'>
         
            <h3 className='text-[12px] text-[#FFF] not-italic font-normal leading-normal tracking-[ 0.5px]'>Above Value</h3>
            <span className='flex custom-green  ml-[6px] -mt-[3px] rounded-[4px] w-5 h-5'></span>
          </div>
          <div className='flex items-center  px-2'>
            <h3 className='text-[12px] text-[#FFF] not-italic font-normal leading-normal tracking-[ 0.5px]'>Below Value</h3>
            <span className='flex  bg-[#F75D5FA3]  ml-[6px] -mt-[3px] rounded-[4px] w-5 h-5'></span>
          </div>
          {/* <div className="flex items-center px-2 mapview-text-white">
          <FontAwesomeIcon icon={faFilterList} />
          </div> */}
        </div>

      </div>
      <div className='flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full -mt-[4.9rem] mapview-style'>
        <Datatable
          columns={columns}
          data={tableData}
          // data={[]}
          title=""
          className={`${tableData.length < 0 ?"":"mt-10"} `}

        >
        </Datatable>
      </div>

    </div>
  )
}

export default CodeCompliance
