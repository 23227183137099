import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  AssignPermissionComponent,
  CalenderComponent,
  ComponentSelectionComponent,
  DashboardComponent,
  LoginComponent,
  MapComponent,
  ProjectManagementComponent,
  RoleManagementComponent,
  SourceOfIntakeComponent,
  StorageReserviorComponent,
  TokenComponent,
  TreatmentFacilityComponent,
  UserManagementComponent,
  SourceOfIntakeTestComponent,
  ScheduleComponent,
  TestSummaryComponent,
  ProjectBasicDetailsComponent,
  WaterQualityStationComponent,
  ScreenMonitoringComponent,
  ChecklistComponent,
  TestCategoryComponent,
  AlertsSolutionHubComponent,
  AlertsSolutionComponent,
  AquaGPTComponent,
  BrochureComponent,
  GPTThreadComponent,
  MapCom,
} from "./routes";
import { FC, useEffect } from "react";
import ProtectedRoute from "./auth/ProtectedRoute";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ScreenMonitoring from "./reusable/ScreenMonitoring";
import MapTest from "./views/pages/Map/MapTest";
import Network from "./views/pages/Map/Network";
import ScreenMonitoring from "./views/pages/ScreenMonitoring";
import Screenmonitoring from "./views/pages/Screen_monitoring";
// import Schedule from "./views/pages/Schedule";
// import SourceOfIntake from "./views/pages/SourceOfIntake";
// import StorageReservior from "./views/pages/StorageReservior";
// import TreatmentFacility from "./views/pages/TreatmentFacility";
// import ComponentSelection from "./views/pages/ComponentSelection";

const App: FC<{}> = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BrochureComponent />}></Route>

        <Route path="/login" element={<LoginComponent />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <ProtectedRoute>
              <UserManagementComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/role-management"
          element={
            <ProtectedRoute>
              <RoleManagementComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assign-permission"
          element={
            <ProtectedRoute>
              <AssignPermissionComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/project-management"
          element={
            <ProtectedRoute>
              <ProjectManagementComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/map"
          element={
            // <Network/>
            <ProtectedRoute>
              <MapComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/network"
          element={
            <Network />
            // <ProtectedRoute>
            //   <MapComponent />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/SourceOfIntake"
          element={
            <ProtectedRoute>
              <SourceOfIntakeComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Checklist"
          element={
            <ProtectedRoute>
              <ChecklistComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/WaterQualityStation"
          element={
            <ProtectedRoute>
              <WaterQualityStationComponent />
            </ProtectedRoute>
          }
        />

        <Route
          path="/StorageReservior"
          element={
            <ProtectedRoute>
              <StorageReserviorComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/TreatmentFacility"
          element={
            <ProtectedRoute>
              <TreatmentFacilityComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ComponentSelection"
          element={
            <ProtectedRoute>
              <ComponentSelectionComponent />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<TokenComponent />}></Route>

        <Route
          path="/Schedule"
          element={
            <ProtectedRoute>
              <ScheduleComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Test"
          element={
            <ProtectedRoute>
              <SourceOfIntakeTestComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Test-Category"
          element={
            <ProtectedRoute>
              <TestCategoryComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/TestSummary"
          element={
            <ProtectedRoute>
              <TestSummaryComponent />
            </ProtectedRoute>
          }
        />

        <Route
          path="/View-Schedule"
          element={
            <ProtectedRoute>
              <CalenderComponent></CalenderComponent>
            </ProtectedRoute>
          }
        ></Route>
        {/* <Route
          path="/Project"
          element={
            <ProtectedRoute>
              <ProjectComponent />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/ProjectBasicDetails"
          element={
            <ProtectedRoute>
              <ProjectBasicDetailsComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Screen-Monitoring"
          element={
            <ProtectedRoute>
              <ScreenMonitoringComponent />
            </ProtectedRoute>
            // <ScreenMonitoring/>
          }
        />
        <Route
          path="/ScreenMonitoring"
          element={
            // <ProtectedRoute>
            <ScreenMonitoring />
            //  </ProtectedRoute>
            // <ScreenMonitoring/>
          }
        />
        <Route
          path="/Screen_monitoring"
          element={
            <ProtectedRoute>
              <Screenmonitoring />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Alerts-Solution"
          element={
            <ProtectedRoute>
              <AlertsSolutionComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Alerts-Solution-Hub"
          element={
            <ProtectedRoute>
              <AlertsSolutionHubComponent />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Aqua-GPT"
          element={
            <ProtectedRoute>
              <AquaGPTComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Aqua-GPT/:thread_id"
          element={
            <ProtectedRoute>
              <GPTThreadComponent />
            </ProtectedRoute>
          }
        />

        <Route path="/Brochure" element={<BrochureComponent />} />
        <Route path="/mapcomp" element={<MapCom />} />

        {/* <Route
          path="/Aqua-GPT"
          element={
            <ProtectedRoute>
              <AquaGPTComponent />
            </ProtectedRoute>
          }
        /> */}
      </Routes>
    </div>
  );
};

export default App;
