import React, { useEffect, useRef, useState } from "react";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
  parse,
  subDays,
} from "date-fns";
import "../../assets/css/reusable/mapviewcalender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { updateMonitoring } from "../../redux/slice/monitoring";
import moment from "moment";

const Calendar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  // const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpen, setIsOpen]: any = useState(false);
  const monitoring = useAppSelector((state) => state.monitoring);
  const calendarRef = useRef<HTMLDivElement>(null);

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <div className="flex justify-between items-center p-1">
        <div className="flex">
          <button onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <span className="mx-2">{format(currentMonth, dateFormat)}</span>
          <button onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(
      updateMonitoring({
        ...monitoring.updatedData,
        schedule_date: moment(selectedDate).format("yyyy-MM-DD"),
      })
    );
  }, [selectedDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderDays = () => {
    const days = [];
    const dateFormat = "EEE";
    const startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="flex justify-center items-center w-10 h-10" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="grid grid-cols-7">{days}</div>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`flex justify-center items-center w-10 h-10 ${
              !isSameMonth(day, monthStart) ? "text-gray-300" : ""
            } ${isSameDay(day, selectedDate) ? "bg-blue-500 text-white" : ""}`}
            key={day.toString()}
            onClick={() => onDateClick(cloneDay)}
          >
            <span>{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="grid grid-cols-7" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div>{rows}</div>;
  };

  const onDateClick = (day: Date) => {
    setSelectedDate(day);
  };

  const handlePreviousDay = () => {
    setSelectedDate(subDays(selectedDate, 1));
  };

  const handleNextDay = () => {
    setSelectedDate(addDays(selectedDate, 1));
  };
  console.log("setSelectedDate", selectedDate);

  return (
    <div className="relative w-96" ref={calendarRef}>
      <div className="flex items-center">
        <button
          onClick={handlePreviousDay}
          className="text-[#FFF] w-[26px] h-6 bg-[#35354B] rounded-sm"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-[#FFF] w-[20rem] text-sm not-italic font-medium leading-normal  bg-transparent flex  text-nowrap justify-center"
        >
          {format(selectedDate, "eeee, d MMMM yyyy  hh:mm:ss a")}
        </button>
        <button
          onClick={handleNextDay}
          className="text-[#FFF]  w-[26px] h-6 bg-[#35354B] rounded-sm"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      {isOpen && (
        <div className="absolute bottom-9 left-16 bg-white border shadow-lg p-2 w-60 mb-1 cursor-pointer calender text-[#FFF]">
          {renderHeader()}
          {renderDays()}
          {renderCells()}
        </div>
      )}
    </div>
  );
};

export default Calendar;
