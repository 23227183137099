import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
interface monitoringState {
    screenStats: {
        load: boolean;
        data: [];
    };
    compliance: {
        load: boolean;
        data: [];
    };
    waterStrech: {
        load: boolean;
        data: [];
    };
    updatedData: {
        schedule_date: string,
        schedule_time: string,
        coordinate_id: string,
        // component_horizontal_tab_id: string
    },
    updateCategoryId: {
        category_id: string
    },
    topParameterComparision: {
        data: [];
        load: boolean
    },
    corelationData: {
        data: [];
        load: boolean
    },
    waterqualityIndex: {
        data: [];
        load: boolean
    },
    visualObservation: {
        data: [],
        load: boolean
    },
    topParameterComparisionGraph: {
        data: [],
        load: boolean
    },
    waterQualityStaions: {
        data: [],
        load: boolean
    },
    parameterPollutantData:{
        data: [],
        load: boolean
    },
    allParameters:{
        data: [],
        load: boolean
    },
    setTopFiveParameter:{
        data: [],
        load: boolean
    },
    getAllParametersGraph:{
        data: [],
        load: boolean
    }


}
export const monitoringSlice = createSlice({
    name: "monitoring",
    initialState: {
        screenStats: {
            load: false,
            data: [],
        },
        compliance: {
            load: false,
            data: [],
        },
        waterStrech: {
            load: false,
            data: [],
        },
        updatedData: {
            schedule_date: new Date().toISOString().split('T')[0],
            schedule_time: "23:55:00",
            coordinate_id: "",
            // component_horizontal_tab_id: ""
        },
        updateCategoryId: {
            category_id: "",
        },
        topParameterComparision: {
            load: false,
            data: [],
        },
        corelationData: {
            data: [],
            load: false
        },
        waterqualityIndex: {
            data: [],
            load: false
        },
        visualObservation: {
            data: [],
            load: false
        },
        topParameterComparisionGraph: {
            data: [],
            load: false
        },
        waterQualityStaions: {
            data: [],
            load: false
        },
        parameterPollutantData: {
            data: [],
            load: false
        },
        allParameters:{
            data: [],
            load: false
        },
        setTopFiveParameter:{
            data: [],
            load: false
        },
        getAllParametersGraph:{
            data: [],
            load: false
        }

    } as monitoringState,
    reducers: {
        updateMonitoring: (state: any, action: any) => {
            state.updatedData = action.payload;
        },
        updateCategory: (state: any, action: any) => {
            state.updateCategoryId = action.payload
        },
        resetwaterqualityindex: (state: any) => {
            state.waterqualityIndex = {
                data: [],
                load: false
            }
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchMonitoringStats.pending, (state: monitoringState, action: any) => {
                state.screenStats.load = true
            })
            .addCase(fetchMonitoringStats.fulfilled, (state: monitoringState, action: any) => {
                state.screenStats.data = action.payload;
                state.screenStats.load = false;
            })
            .addCase(fetchMonitoringStats.rejected, (state: monitoringState, action: any) => {
                state.screenStats.load = false
            })
            .addCase(fetchCompliance.pending, (state: monitoringState, action: any) => {
                state.compliance.load = true
            })
            .addCase(fetchCompliance.fulfilled, (state: monitoringState, action: any) => {
                state.compliance.data = action.payload;
                state.compliance.load = false;
            })
            .addCase(fetchCompliance.rejected, (state: monitoringState, action: any) => {
                state.compliance.load = false
            })
            .addCase(monitoringScreenWaterStrech.pending, (state: monitoringState, action: any) => {
                state.waterStrech.load = true
            })
            .addCase(monitoringScreenWaterStrech.fulfilled, (state: monitoringState, action: any) => {
                state.waterStrech.data = action.payload;
                state.waterStrech.load = false;
            })
            .addCase(monitoringScreenWaterStrech.rejected, (state: monitoringState, action: any) => {
                state.waterStrech.load = false
            })
            .addCase(fetchTopParameters.pending, (state: monitoringState, action: any) => {
                state.topParameterComparision.load = true
            })
            .addCase(fetchTopParameters.fulfilled, (state: monitoringState, action: any) => {
                state.topParameterComparision.data = action.payload;
                state.topParameterComparision.load = false;
            })
            .addCase(fetchTopParameters.rejected, (state: monitoringState, action: any) => {
                state.topParameterComparision.load = false
            })
            .addCase(getCorelationData.pending, (state: monitoringState, action: any) => {
                state.corelationData.load = true
            })
            .addCase(getCorelationData.fulfilled, (state: monitoringState, action: any) => {
                state.corelationData.data = action.payload;
                state.corelationData.load = false;
            })
            .addCase(getCorelationData.rejected, (state: monitoringState, action: any) => {
                state.corelationData.load = false
            })
            .addCase(getWaterqualityIndexData.pending, (state: monitoringState, action: any) => {
                state.waterqualityIndex.load = true
            })
            .addCase(getWaterqualityIndexData.fulfilled, (state: monitoringState, action: any) => {
                state.waterqualityIndex.data = action.payload;
                state.waterqualityIndex.load = false;
            })
            .addCase(getWaterqualityIndexData.rejected, (state: monitoringState, action: any) => {
                state.waterqualityIndex.load = false
            })
            .addCase(getComparisionGraph.pending, (state: monitoringState, action: any) => {
                state.topParameterComparisionGraph.load = true
            })
            .addCase(getComparisionGraph.fulfilled, (state: monitoringState, action: any) => {
                state.topParameterComparisionGraph.data = action.payload;
                state.topParameterComparisionGraph.load = false;
            })
            .addCase(getComparisionGraph.rejected, (state: monitoringState, action: any) => {
                state.topParameterComparisionGraph.load = false
            })
            .addCase(getWaterQualityStaions.pending, (state: monitoringState, action: any) => {
                state.waterQualityStaions.load = true
            })
            .addCase(getWaterQualityStaions.fulfilled, (state: monitoringState, action: any) => {
                state.waterQualityStaions.data = action.payload;
                state.waterQualityStaions.load = false;
            })
            .addCase(getWaterQualityStaions.rejected, (state: monitoringState, action: any) => {
                state.waterQualityStaions.load = false
            })
            .addCase(fetchParametersSourcePollutantsData.pending, (state: monitoringState, action: any) => {
                state.parameterPollutantData.load = true
            })
            .addCase(fetchParametersSourcePollutantsData.fulfilled, (state: monitoringState, action: any) => {
                state.parameterPollutantData.data = action.payload;
                state.parameterPollutantData.load = false;
            })
            .addCase(fetchParametersSourcePollutantsData.rejected, (state: monitoringState, action: any) => {
                state.parameterPollutantData.load = false
            })
            .addCase(fetchAllParameter.pending, (state: monitoringState, action: any) => {
                state.allParameters.load = true
            })
            .addCase(fetchAllParameter.fulfilled, (state: monitoringState, action: any) => {
                state.allParameters.data = action.payload;
                state.allParameters.load = false;
            })
            .addCase(fetchAllParameter.rejected, (state: monitoringState, action: any) => {
                state.allParameters.load = false
            })
            .addCase(setTopFiveParameter.pending, (state: monitoringState, action: any) => {
                state.setTopFiveParameter.load = true
            })
            .addCase(setTopFiveParameter.fulfilled, (state: monitoringState, action: any) => {
                state.setTopFiveParameter.data = action.payload;
                state.setTopFiveParameter.load = false;
            })
            .addCase(setTopFiveParameter.rejected, (state: monitoringState, action: any) => {
                state.setTopFiveParameter.load = false
            })
            .addCase(fetchAllParametersGraphData.pending, (state: monitoringState, action: any) => {
                state.getAllParametersGraph.load = true
            })
            .addCase(fetchAllParametersGraphData.fulfilled, (state: monitoringState, action: any) => {
                state.getAllParametersGraph.data = action.payload;
                state.getAllParametersGraph.load = false;
            })
            .addCase(fetchAllParametersGraphData.rejected, (state: monitoringState, action: any) => {
                state.getAllParametersGraph.load = false
            })
            .addCase(getObservationImages.pending, (state: monitoringState, action: any) => {
                state.visualObservation.load = true
            })
            .addCase(getObservationImages.fulfilled, (state: monitoringState, action: any) => {
                state.visualObservation.data = action.payload;
                state.visualObservation.load = false;
            })
            .addCase(getObservationImages.rejected, (state: monitoringState, action: any) => {
                state.visualObservation.load = false
            })


    },
});
export default monitoringSlice.reducer;
export const { updateMonitoring, updateCategory, resetwaterqualityindex } = monitoringSlice.actions;

export const fetchMonitoringStats = createAsyncThunk(
    "monitoring/fetchMonitoringStats",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/monitoring-screen-stats", param);

            return data;
        } catch (error) {
        }
    }
);

export const monitoringScreenWaterStrech = createAsyncThunk(
    "monitoring/monitoringScreenWaterStrech",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/monitoring-screen-water-strech", param);

            return data;
        } catch (error) {
        }
    }
);

export const fetchCompliance = createAsyncThunk(
    "monitoring/fetchCompliance",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/get-code-compliance-data", param);

            return data;
        } catch (error) {
        }
    }
);

export const fetchTopParameters = createAsyncThunk(
    "monitoring/fetchTopParameters",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/get-top-parameters-graph-data", param)
            return data;
        }
        catch (error) {

        }
    }
)

export const getCorelationData = createAsyncThunk(
    "monitoring/getCorealtionData",
    async () => {
        try {
            const { data } = await DataServer.get("api/get-parameters-corelations-data")
            return data;
        }
        catch (error) {

        }
    }
)

export const getComparisionGraph = createAsyncThunk(
    "monitoring/getComparisionGraph",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/get-top-parameters-comparison-graph-data", param)
            return data;
        }
        catch (error) {

        }
    }
)
export const getWaterqualityIndexData = createAsyncThunk(
    "monitoring/getWaterqualityIndexData",
    async (params: any) => {
        try {
            const { data } = await DataServer.post("api/get-water-quality-index-class-data", params)
            return data;
        }
        catch (error) {

        }
    }
)

export const getWaterQualityStaions = createAsyncThunk(
    "monitoring/getWaterQualityStaions",
    async (param: any) => {
        try {

            const { data } = await DataServer.post("api/get-water-quality-station-data", param)
            return data;
        }
        catch (error) {

        }
    }
)

export const fetchParametersSourcePollutantsData = createAsyncThunk(
    "monitoring/fetchParametersSourcePollutantsData",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/get-parameters-source-of-pollutants-data", param)
            return data;
        }
        catch(error){}

    }
)
export const fetchAllParameter=createAsyncThunk(
    "monitoring/fetchAllParameter",
    async(param: any)=>{
        try{
            const { data } = await DataServer.post("api/view-all-parameters", param)
            return data;
        }
        catch(error){}
    }
)

export const setTopFiveParameter=createAsyncThunk(
    "monitoring/setTopFiveParameter",
    async(param: any)=>{
        try{
            const { data } = await DataServer.post("api/set-top-five-parameters", param)
            return data;
        }
        catch(error){}
    }
)

export const fetchAllParametersGraphData=createAsyncThunk(
    "monitoring/fetchAllParametersGraphData",
    async(param: any)=>{
        try{
            const { data } = await DataServer.post("api/get-all-parameters-graph-data", param)
            return data;
        }
        catch(error){}
    }
)
export const getObservationImages = createAsyncThunk(
    "monitoring/getObservationImages",
    async (params: any) => {
        try {
            const { data } = await DataServer.post("api/get-visual-observation-images-data", params)
            return data;
        }
        catch (error) {

        }
    }
)
