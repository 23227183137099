import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { NavLink } from 'react-router-dom';
// import React from 'react'

const NoData = (props: any) => {
  return (

    <>
      <div className="w-full flex justify-center flex-col text-center mt-12">
        <div className='flex justify-center'>
          <div className='rounded-full w-36 h-36 bg-[#F8FAFD;] flex items-center justify-center'>
            <FontAwesomeIcon icon={faUserPlus} className=' w-16 h-14  text-[#132144] text-opacity-50' />
          </div>
        </div>

        <h2 className='text-[#132144] text-xl not-italic font-semibold leading-[18.9px] mt-8'>No {props.title} Created</h2>

        <p className='text-[#71869D] text-sm not-italic font-normal leading-7 pt-4' dangerouslySetInnerHTML={{
          __html: props.subtitle

        }}></p>
        <div className='flex justify-center pt-8'>

          <button className="px-4 py-3 text-white text-sm not-italic font-normal leading-[22px] rounded-md border border-solid border-[#377DFF] bg-[#377DFF] flex focus:outline-0" >
            <NavLink to="">Create Role</NavLink>
          </button>
        </div>

      </div>
    </>
  )
}

export default NoData
