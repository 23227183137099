import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EChartsReact from "echarts-for-react";
import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks';

const CorelationPopup = (props: any) => {
    const dispatch = useAppDispatch();
    // const corelationData=useAppSelector((state)=>state.corelationData)
    const monitoring = useAppSelector((state) => state.monitoring)
    //************************ */ paramter corelation data code starts here ***********
    const allParameterData = monitoring?.corelationData?.data?.data?.allParameters
    const parameterCorelations = monitoring?.corelationData?.data?.data?.parameterCorelations
    // console.log('corelationData',corelationData)
    console.log('allParameterData', allParameterData)
    console.log('parameterCorelations', parameterCorelations)

    const dirDist50 = "#E8544E";
    const dirDist10 = "#FFD265";
    const dirDistLess10 = "#2AA775";
    const colors = [dirDist50, dirDist10, dirDistLess10];
    const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

    const truncateName = (name: string) => {
        return name.length > 3 ? name.substring(0, 3) + '...' : name;
    };
    const getRandomSize = (minSize = 10, maxSize = 50) => {
        return Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
    };
    // Mapping through all parameters to create the data array for the graph
    const ParameterData = allParameterData?.map((param: any) => {
        return {
            // name: truncateName(param),
            name: param,
            symbolSize: getRandomSize(), // You can set a default or fetch this value from the API
            itemStyle: { color: param.color || getRandomColor() }, // Default color or fetch from param
            draggable: true,
            label: {
                show: true,
                fontSize: 10, // Adjust font size here
                color:'black'

            },
        };
    });
    const parametername = ParameterData?.map((data: any) => (
        data.name
    ))
    console.log('parametername', parametername)
    // Mapping through parameter correlations to create the links array for the graph
    const graphLinks = parameterCorelations?.map((correlation: any) => {
        return {
            source: correlation.base_parameter,
            target: correlation.relation_parameter,
        };
    });
    console.log('graphLinks', graphLinks)

    // console.log('parameterName',parameterName)
    //************************* */ paramter corelation data code ends here ****************************

const  option={ 
        // title: {
        //     text: 'South Korea domestic flight routes'
        //   },
        tooltip: {
            formatter: function (params: any) {

                const parameterName = params.data.name;
                // const additionalInfo = params.data.someAdditionalInfo || ''; 
                return '<b>' + parameterName + '</b>';
            }
        },
        series: [{
            type: 'graph',
            // layout: 'force',

            force: {
                repulsion: 96,
                edgeLength: 80,
                //   draggable: false,
            },
            //    draggable: true,
            // roam: true,

            label: {
                show: true
            },

            data: ParameterData,
            links: graphLinks,
            layout: 'force',
            roam: true, // Enable roaming
            focusNodeAdjacency: true, // Highlight the adjacent nodes when hovering
            lineStyle: {
                color: '#000', // Color of the lines
                width: 1,      // Line width
                curveness: 0 // Curve degree of the lines
            },
            emphasis: {
                lineStyle: {
                    width: 2 // Highlight line width on hover
                }
            }
        }],
        grid: {
            left: '0%',
            right: '0%', // Adjust this to ensure proper fitting
            bottom: '3%',
            containLabel: true,

        },
        scaleLimit: {
            min: 1.3,  // Minimum zoom level
            max: 1.4    // Maximum zoom level
        }
    }
    const chartRef = useRef<echarts.ECharts | null>(null);

    // useEffect(() => {
    //     const chartInstance = chartRef.current?.getEchartsInstance();
    //     const container = chartInstance?.getDom();
        
    //     const handlePan = () => {
    //         if (container) {
    //             const containerRect = container.getBoundingClientRect();
    //             const chartRect = chartInstance?.getBoundingRect();

    //             // Get chart offset values
    //             const offsetLeft = containerRect.left - chartRect.left;
    //             const offsetTop = containerRect.top - chartRect.top;

    //             // Restrict chart position
    //             chartInstance?.on('datazoom', () => {
    //                 const view = chartInstance?.getOption()?.series[0]?.dataZoom;
    //                 if (view) {
    //                     // Calculate new bounds and adjust if necessary
    //                     if (view.start < offsetLeft) {
    //                         chartInstance?.dispatchAction({
    //                             type: 'dataZoom',
    //                             start: offsetLeft,
    //                         });
    //                     }
    //                     if (view.end > containerRect.width - chartRect.width) {
    //                         chartInstance?.dispatchAction({
    //                             type: 'dataZoom',
    //                             end: containerRect.width - chartRect.width,
    //                         });
    //                     }
    //                 }
    //             });
    //         }
    //     };

    //     handlePan();
    //     window.addEventListener('resize', handlePan); // Adjust on window resize
    //     return () => window.removeEventListener('resize', handlePan);
    // }, [option]);
    return (
        <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full paramter-popup ">
            <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex justify-between items-center py-3 border-b px-5">
                <h2 className='text-[#000] font-[inter] text-[15.15px] not-italic font-normal leading-normal'>Parameter Correlation</h2>
                <FontAwesomeIcon icon={faXmark} size='lg' className='text-[#42526E] cursor-pointer' onClick={props.onclickevent} />
            </div>
            <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full pl-5  flex flex-wrap overflow-hidden relative">
                <EChartsReact
                    option={option}
                    // ref={chartRef}
                    style={{ width: "100%", height: "600px" }}
                />
            </div>
        </div>
    )
}

export default CorelationPopup
