import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EChartsReact from 'echarts-for-react';
import React, { useEffect, useState } from 'react'
import '../../assets/css/reusable/newdashboard.css'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchAllParameter, fetchAllParametersGraphData, fetchTopParameters, setTopFiveParameter } from '../../redux/slice/monitoring';
import Loading from '../../views/pages/Loading';

const ParameterGraphPopup = ({ closeModal, id, monitoringData}: any) => {
    const dispatch = useAppDispatch();
    const monitoring = useAppSelector((state) => state.monitoring);
    const [option, setOption] = React.useState<any>(null);
    const [optionAllParameters, setOptionAllParameters] = React.useState<any>(null);
    const [parametersInGraph, setParametersInGraph] = useState<any[]>([]);
    const [selectedParameters, setSelectedParameters] = useState([]);
    const [loading, setLoading]: any = useState(false);
    const [allParameters, setAllParameters] = useState([]);
    const [topFiveParameters, setTopFiveParameters] = useState([]);
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({}); // Initially an empty 
    const [selectedValue, setSelectedValue] = useState<string>('Daily');
    const [fetchParameters,SetFetchParameters]=useState(false);
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    console.log('coordinatemonitoringData', monitoringData)
   
    console.log('topFiveParameterssssss',topFiveParameters)
    console.log('selectedParameters',topFiveParameters)
    useEffect(() => {
        const data = {
            coordinate_id: monitoringData?.coordinate_id,
            category_id: monitoring?.updateCategoryId,
            page_flag:'settopfive'
        }
        if (data.coordinate_id && data.category_id) {
            dispatch(fetchAllParameter(data))
            SetFetchParameters(false)
        }
    }, [monitoringData?.coordinate_id, monitoring?.updateCategoryId,fetchParameters])

    // const allParameters=monitoring?.allParameters?.data?.data?.allParameters || [];
    console.log('allParameters',allParameters)

    

    // const topFiveParameters=monitoring?.allParameters?.data?.data?.topFiveParameters || [];

    console.log('topFiveParameters',topFiveParameters)
    const onSelectChange = (e: any) => {
        const value = e.target.value;
        setSelectedValue(value);
        console.log("Selected Value:", value);
      };
    
    // *************************code for top paramter graph starts here **********************************8
    useEffect(() => {
        if (monitoring && selectedValue) {
            let obj = {
                ...monitoringData,
                category_id: monitoring.updateCategoryId,
                occurrence: selectedValue
            }
            console.log('gettopparameter', obj)
            console.log('monitoringData', monitoringData)
            if (obj.category_id && obj.occurrence && obj.coordinate_id && obj.schedule_date && obj.schedule_time) {
                dispatch(fetchTopParameters(obj))
                SetFetchParameters(false)
            }

        }
    }, [monitoringData, selectedValue,fetchParameters])

    const topParameterComparision = monitoring.topParameterComparision?.data?.data
    console.log('topParameterComparision', topParameterComparision)

    const parameterName = topParameterComparision?.map((data: any) => (
        data?.parameter_name || 'N/A'
    ))
    console.log('parameter_name', parameterName)

    useEffect(() => {
        const fetchData = () => {
            if (topParameterComparision?.length === 0) return;

            const _rawData = topParameterComparision?.map((item: any) => {
                const samplingTime = item?.sampling_time ? parseFloat(item.sampling_time.split(':')[0]) : null;
                //  const observationValue = item?.observation !== undefined ? parseFloat(item.observation) : 10;
                return {
                    sampling_time: samplingTime, // Ensure sampling_time is numeric and correctly extracted
                    parameter_name: item?.parameter_name,
                    observation: parseFloat(item?.observation),
                };
            });

            //   run(_rawData);

            // Remove duplicates and update parametersInGraph
            const uniqueParameters = Array.from(new Set(_rawData?.map((item: any) => item.parameter_name)));
            setParametersInGraph(uniqueParameters); // Correctly update the state with the array of strings

            run(_rawData);
        };

        const run = (_rawData: any) => {
            const parameters = Array.from(new Set(_rawData?.map((item: any) => item?.parameter_name)));

            const datasetWithFilters: any = [];
            const seriesList: any = [];

            parameters.forEach((parameter) => {
                const datasetId = `dataset_${parameter}`;

                // Ensure unique datasetId by checking if it already exists
                if (!datasetWithFilters.some((dataset: { id: string; }) => dataset.id === datasetId)) {
                    datasetWithFilters.push({
                        id: datasetId,
                        fromDatasetId: 'dataset_raw',
                        transform: {
                            type: 'filter',
                            config: {
                                and: [
                                    { dimension: 'sampling_time', gte: 0 },
                                    { dimension: 'parameter_name', '=': parameter },
                                ],
                            },
                        },
                    });

                    seriesList.push({
                        type: 'line',
                        datasetId,
                        showSymbol: true, // Always show symbols (dots)
                        symbolSize: 8,
                        smooth: true,
                        name: parameter,
                        endLabel: {
                            show: false,
                            formatter: (params: any) => `Parameter: ${params.seriesName}`,
                        },
                        labelLayout: {
                            moveOverlap: 'shiftY',
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        encode: {
                            x: 'sampling_time',
                            y: 'observation',
                            label: ['parameter_name', 'observation'],
                            itemName: 'sampling_time',
                            tooltip: ['observation'],
                        },
                    });
                }
            });

            const option = {
                animationDuration: 6000,
                dataset: [
                    {
                        id: 'dataset_raw',
                        source: _rawData,
                    },
                    ...datasetWithFilters,
                ],
                title: {
                    text: '',
                },
                tooltip: {
                    order: 'valueDesc',
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    name: 'Time',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontSize: 14,
                        padding: 10,
                    },
                },
                yAxis: {
                    type: 'value', // Ensure this exists and matches your data type
                    // additional yAxis configuration if needed
                },
                // yAxis: [
                //     {
                //         type: 'value',
                       
                //         min: 0,
                //         max: Math.max(..._rawData?.map((item: any) => item?.observation), 0) + 6, 
                //         interval: 2,
                //         nameLocation: 'start',
                       
                //         axisLabel: {
                //             formatter: '{value}'
                //         }
                //     }
                // ],
                grid: {
                    right: 20,
                    left: 30,
                    bottom: 60,
                    top: 25,
                },
                series: seriesList,
            };

            setOption(option);
        };

        fetchData();
    }, [topParameterComparision,selectedValue]);

    // Filter topFiveParameters to only include those that have a line present on the graph
    // const filteredTopFiveParameters = topFiveParameters?.filter((param: any) =>
    //     parametersInGraph?.includes(param)
    // );
    // console.log('filteredTopFiveParameters', filteredTopFiveParameters)
    // *************************code for top paramter graph ends here **********************************8


    // *************************code for all paramter graph ends here **********************************8

    useEffect(() => {
        if (monitoring && selectedValue) {
            let obj = {
                ...monitoringData,
                category_id: monitoring.updateCategoryId,
                occurrence: selectedValue
            }
            console.log('getallparameterefefefe', obj)
            console.log('monitoringData', monitoringData)

            if (obj.category_id && obj.occurrence && obj.coordinate_id && obj.schedule_date && obj.schedule_time) {
                dispatch(fetchAllParametersGraphData(obj))
            }

        }
    }, [monitoringData,selectedValue])

    const getAllParametersGrpah = monitoring?.getAllParametersGraph?.data?.data

    console.log('getAllParametersGrpah', getAllParametersGrpah)

    useEffect(() => {
        const fetchData = () => {
            if (getAllParametersGrpah?.length === 0) return;

            const _rawData = getAllParametersGrpah?.map((item: any) => {
                const samplingTime = item?.sampling_time ? parseFloat(item.sampling_time.split(':')[0]) : null;
                const observationValue = item?.observation !== undefined ? parseFloat(item.observation) : 10; 
                return {
                    sampling_time: samplingTime, // Ensure sampling_time is numeric and correctly extracted
                    parameter_name: item?.parameter_name,
                    // observation: observationValue,
                    observation: parseFloat(item?.observation),
                };
            });

            //   run(_rawData);

            // Remove duplicates and update parametersInGraph
            //   const uniqueParameters = Array.from(new Set(_rawData?.map((item: any) => item.parameter_name)));
            //   setParametersInGraph(uniqueParameters); // Correctly update the state with the array of strings

            run(_rawData);
        };

        const run = (_rawData: any) => {
            const parameters = Array.from(new Set(_rawData?.map((item: any) => item?.parameter_name)));

            const datasetWithFilters: any = [];
            const seriesList: any = [];

            parameters.forEach((parameter) => {
                const datasetId = `dataset_${parameter}`;

                // Ensure unique datasetId by checking if it already exists
                if (!datasetWithFilters.some((dataset: { id: string; }) => dataset.id === datasetId)) {
                    datasetWithFilters.push({
                        id: datasetId,
                        fromDatasetId: 'dataset_raw',
                        transform: {
                            type: 'filter',
                            config: {
                                and: [
                                    { dimension: 'sampling_time', gte: 0 },
                                    { dimension: 'parameter_name', '=': parameter },
                                ],
                            },
                        },
                    });

                    seriesList.push({
                        type: 'line',
                        datasetId,
                        showSymbol: true, // Always show symbols (dots)
                        symbolSize: 8,
                        smooth: true,
                        name: parameter,
                        endLabel: {
                            show: false,
                            formatter: (params: any) => `Parameter: ${params.seriesName}`,
                        },
                        labelLayout: {
                            moveOverlap: 'shiftY',
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        encode: {
                            x: 'sampling_time',
                            y: 'observation',
                            label: ['parameter_name', 'observation'],
                            itemName: 'sampling_time',
                            tooltip: ['observation'],
                        },
                    });
                }
            });

            const option2 = {
                animationDuration: 6000,
                dataset: [
                    {
                        id: 'dataset_raw',
                        source: _rawData,
                    },
                    ...datasetWithFilters,
                ],
                title: {
                    text: '',
                },
                tooltip: {
                    order: 'valueDesc',
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    name: 'Time',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontSize: 14,
                        padding: 10,
                    },
                },
                yAxis: {
                    type: 'value', // Ensure this exists and matches your data type
                    // additional yAxis configuration if needed
                },
                // yAxis: [
                //     {
                //         type: 'value',
                //         min: 0,
                //         max: Math.max(..._rawData?.map((item: any) => item?.observation), 0) + 6, 
                //         interval: 2,
                //         nameLocation: 'start',
                //         axisLabel: {
                //             formatter: '{value}'
                //         }
                //     }
                // ],
                grid: {
                    right: 20,
                    left: 30,
                    bottom: 60,
                    top: 25,
                },
                series: seriesList,
            };

      
            setOptionAllParameters(option2);
        };

        fetchData();
    }, [getAllParametersGrpah,selectedValue]);
    // *************************code for all paramter graph ends here **********************************8
  

    
    useEffect(() => {
        if (topFiveParameters.length > 0) {
            const initialCheckedItems = topFiveParameters.reduce((acc: any, param: any) => {
                acc[param] = true; // Set default as checked
                return acc;
            }, {});
            setCheckedItems(initialCheckedItems); // Initialize checkedItems state
        }
    }, [topFiveParameters]);
    // const [checkedItems, setCheckedItems] = useState(
    //     topFiveParameters?.reduce((acc: any, data: any) => {
    //         acc[data] = true; // Set default checked for each parameter
    //         return acc;
    //     }, {})
    // );

    console.log('checkedItems', checkedItems);

    // Handle checkbox change
    const handleCheckboxChange = (param: any) => {
        setCheckedItems((prevCheckedItems: any) => ({
            ...prevCheckedItems,
            [param]: !prevCheckedItems[param],
        }));
    };
    const [checkedItems2, setCheckedItems2] = useState(
        allParameters?.reduce((acc: any, param: any) => {
            acc[param] = true; // Set default checked for each parameter in filteredTopFiveParameters
            return acc;
        }, {})
    );

    console.log('checkedItems2', checkedItems2)
    // const handleCheckboxChange2 = (parameter: any) => {
    //     setCheckedItems2((prevCheckedItems: any) => ({
    //         ...prevCheckedItems,
    //         [parameter?.parameter_name]: !prevCheckedItems[parameter?.parameter_name],
    //     }));

    //     setSelectedParameters((prevSelected: any) => {
    //         if (prevSelected.includes(parameter?.parameter_name)) {
    //             return prevSelected.filter((p: any) => p !== parameter?.parameter_name || 'N/A');
    //         } else {
    //             return [parameter?.parameter_name || 'N/A'];
    //         }
    //     });
    // };
    const handleCheckboxChange2 = (parameter: any) => {
        setCheckedItems2((prevCheckedItems: any) => ({
            ...prevCheckedItems,
            [parameter?.parameter_name]: !prevCheckedItems[parameter?.parameter_name],
        }));
    
        setSelectedParameters((prevSelected: any) => {
            // If the parameter is already in the selected list, remove it
            if (prevSelected.includes(parameter?.parameter_name)) {
                return prevSelected.filter((p: any) => p !== parameter?.parameter_name);
            } 
            // Otherwise, append it to the list
            else {
                return [...prevSelected, parameter?.parameter_name];
            }
        });
    };

    const onSave = async () => {
        // Filter to include only the checked parameters
        setLoading(true); // Start loading
        SetFetchParameters(true)
        const selectedTopFiveParameters = topFiveParameters?.filter(
            (param) => checkedItems[param]
        );

        const data = {
            coordinate_id: monitoringData?.coordinate_id,
            category_id: monitoring?.updateCategoryId,
            parameters: [...selectedTopFiveParameters, ...selectedParameters].join(',') // Join the selected parameters with a comma
        };

        if (data.coordinate_id && data.category_id && data.parameters) {
            await dispatch(setTopFiveParameter(data));
        }
        // setTopFiveParameters(monitoring?.allParameters?.data?.data?.topFiveParameters)
        setLoading(false); // End loading
    };
    
    
    useEffect(() => {
        setAllParameters(monitoring?.allParameters?.data?.data?.allParameters || [])
    }, [monitoring?.allParameters?.data?.data?.allParameters])

    useEffect(() => {
        // if(monitoring?.allParameters?.data?.data?.topFiveParameters){
            
            setTopFiveParameters(monitoring?.allParameters?.data?.data?.topFiveParameters || [])
        // }
    }, [monitoring?.allParameters?.data?.data?.topFiveParameters])

    // useEffect(() => {
    //     if(monitoring?.setTopFiveParameter?.data?.data?.five_parameters){
            
    //         setTopFiveParameters(monitoring?.setTopFiveParameter?.data?.data?.five_parameters || [])
    //     }
    // }, [monitoring?.setTopFiveParameter?.data?.data?.five_parameters])


    console.log('allParameterssssssssss',allParameters,topFiveParameters)
   
    return (
        <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full paramter-popup">
            <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex justify-between items-center py-3 border-b px-5">
                <h2 className='text-[#000] font-[inter] text-[15.15px] not-italic font-normal leading-normal'>Parameter Comparison</h2>
                <FontAwesomeIcon icon={faXmark} size='lg' className='text-[#42526E] cursor-pointer' onClick={closeModal} />
            </div>
            <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full pl-5  flex flex-wrap">
                <div className="w-3/4 pr-5 border-r pt-3 custom-popup-height">
                    <div className="flex w-full justify-end">
                        <select name="select" id="" className='text-[#6D6D6D] text-xs not-italic font-normal leading-normal mt-4'  onChange={onSelectChange}>
                            <option value="Daily" selected className=''>Daily</option>
                            <option value="Yearly">Yearly</option>
                            {/* <option value="Weekly">Weekly</option> */}
                        </select>
                    </div>
                    {
                        id === 5 ?
                            (
                                option && <EChartsReact option={option} style={{ height: '600px' }} /> 
                            ) :
                            (
                                optionAllParameters && <EChartsReact option={optionAllParameters} style={{ height: '600px' }} /> 
                            )
                    }


                </div>
                <div className="w-1/4 py-5 pl-5 ">
                    <h2 className='text-[#000] font-[Inter] text-sm not-italic font-medium leading-normal pr-5'>Parameter List</h2>
                    <div className="relative  my-2 pr-5"><FontAwesomeIcon icon={faMagnifyingGlass} className='text-[#71869D] absolute top-[10px] left-3 text-sm' />
                        <input type="search" placeholder="Search" className="border border-[#EFF1F7] pl-9 placeholder:text-[#71869D] placeholder:text-[14px] py-[6px] rounded-md 
                        text-[#71869D] text-xs not-italic font-normal leading-5 w-full" />
                    </div>
                    <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full h-[72vh] overflow-y-auto pr-5 parameter-list-height relative'>
                        {
                            id === 5 ? <>
                                {
                                    loading === true ? <><Loading /></> : <>
                                        <div className='border-b pb-3'>
                                            <div className='text-[#000] text-xs not-italic font-medium leading-normal font-[Inter]'>Selected Parameter</div>
                                            {
                                             topFiveParameters && topFiveParameters?.map((data: any) => (

                                                    <ul key={data.id}>
                                                        <li className='flex justify-between items-center my-3'>
                                                            <div className='flex justify-between items-center'>
                                                                <input type="checkbox"
                                                                    checked={checkedItems[data]}
                                                                    onChange={() => handleCheckboxChange(data)}
                                                                    name="" id="" className='mr-2' />
                                                                <div className='text-[#2D2D2D] text-sm not-italic font-normal leading-normal tracking-[0.5px] font-[Inter]'>{data}</div>
                                                                <div className='bg-[#377DFF] border w-[10px] h-[10px] rounded-full ml-2'></div>
                                                            </div>
                                                            <div className='text-[#2D2D2D] text-sm not-italic font-normal leading-normal tracking-[0.5px] font-[Inter]'> {data.percent}</div>
                                                        </li>
                                                    </ul>
                                                ))
                                            }
                                        </div>
                                        <div className='text-[#000] text-xs not-italic font-medium leading-normal font-[Inter] pt-3'>Remaining Parameter</div>

                                        {
                                          allParameters &&  allParameters?.map((data: any) => (

                                                <ul key={data?.id}>
                                                    <li className='flex justify-between items-center my-3'>
                                                        <div className='flex justify-between items-center'>
                                                            <input type="checkbox"
                                                                // checked={checkedItems2[data]}  
                                                                onChange={() => handleCheckboxChange2(data)} name="" id="" className='mr-2' />
                                                            <div className='text-[#2D2D2D] text-sm not-italic font-normal leading-normal tracking-[0.5px] font-[Inter]'>{data?.parameter_name}</div>
                                                            <div className='bg-[#377DFF] border w-[10px] h-[10px] rounded-full ml-2'></div>
                                                        </div>
                                                        <div className='text-[#2D2D2D] text-sm not-italic font-normal leading-normal tracking-[0.5px] font-[Inter]'>{data.percent}</div>
                                                    </li>
                                                </ul>
                                            ))
                                        }

                                        <div className='w-full mt-4 flex justify-end absolute bottom-1 right-5'>
                                            <button className="text-white text-sm font-normal not-italic leading-[21px] py-[11px] px-[17px] rounded-md boredr border-solid border-[#377DFF] bg-[#377DFF] ml-4 save-btn" onClick={onSave}>Save</button>
                                        </div>
                                    </>
                                }
                            </>
                                : <>
                                    {
                                     allParameters &&  allParameters?.map((data: any) => (
                                            <ul key={data?.id}>
                                                <li className='flex justify-between items-center my-3'>
                                                    <div className='flex justify-between items-center'>
                                                        <div className='border w-[10px] h-[10px] rounded-full mr-2' style={{ backgroundColor: getRandomColor() || '#000' }}></div>
                                                        <div className='text-[#2D2D2D] text-sm not-italic font-normal leading-normal tracking-[0.5px] font-[Inter]'>{data?.parameter_name}</div>
                                                    </div>
                                                    {/* <div className='text-[#2D2D2D] text-sm not-italic font-normal leading-normal tracking-[0.5px] font-[Inter]'>{data.percent}</div> */}
                                                </li>
                                            </ul>
                                        ))
                                    }
                                </>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParameterGraphPopup
