import React, { useEffect, useState } from 'react'
import PageTitle from '../PageTitle'
import '../../assets/css/reusable/screenmonitoring.css'
import { useAppSelector } from '../../hooks';

const CalculationMethod = () => {
    const monitoring = useAppSelector((state) => state.monitoring);
    const [calculationData, setCalculationData]: any = useState(null);
    useEffect(() => {
        if (monitoring.screenStats.data?.getWaterQualityIndex) {
            // transformData(monitoring.screenStats.data.getWaterQualityIndex)
            setCalculationData(transformData(monitoring.screenStats.data.getWaterQualityIndex))
        } else {
            setCalculationData(transformData())
        }
    }, [monitoring.screenStats.data?.getWaterQualityIndex])
    const classifyRange = (range: any) => {
        if (range >= 63 && range <= 100) {
            return "Good to Excellent";
        } else if (range >= 50 && range < 63) {
            return "Medium to Good";
        } else if (range >= 38 && range < 50) {
            return "Bad";
        } else if (range < 38) {
            return "Bad to Very Bad";
        } else {
            return "-"; // for handling null or undefined values
        }
    };

    const transformData = (data?: any) => {
        return [
            {
                id: 1,
                name: "NSFWQI",
                range: data?.nfs_wqi || "N/A", // Assuming you might have a value here, or default to "N/A"
                // name: data.nfs_category || "Unknown", // Assuming you might have a value here, or default to "Unknown"
                btndata: data?.nfs_category || "-",
                color: data?.nfs_color_code
            },
            {
                id: 2,
                name: "Weighted Arithmetic",
                range: data?.weighted_arithmetic_wqi || "N/A",
                //name: data.weighted_arithmetic_category || "Unknown",
                btndata: data?.weighted_arithmetic_category || "-",
                color: data?.weighted_arithmetic_color_code
            },
            {
                id: 3,
                name: "Fuzzy Logic",
                range: data?.fuzzy_logic_wqi || "N/A",
                //name: data.fuzzy_logic_category || "Unknown",
                btndata: data?.fuzzy_logic_category || "-",
                color: data?.fuzzy_logic_color_code
            },
        ];
    };
    return (
        <div className='flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-4 pt-3 rounded-[7.452px] calculation-method h-[254px] bg-[#222230] '>
            {/* <PageTitle title="Surface WQI By Different Calculation Method" ></PageTitle> */}
            <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full h-8 border-b border-[#4A4A68]'>
            <h1 className="text-[#FFF] font-bold text-[22px] ">Water Quality Index </h1>
            </div>
          <div className='w-full -mt-[5rem]'>

         
            <div className="w-full flex flex-wrap">
                {calculationData &&
                    calculationData.map((data: any) => (
                        <div className='flex flex-wrap w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full h-10'>
                            {/* <div className="w-full flex flex-wrap py-2"> */}
                          
                            <div className="w-2/4 text-[#FFF] text-[12px] font-medium not-italic leading-normal tracking-[0.5px] flex items-center justify-between  rounded-md bg-row">
                                <span className='ml-3'>{data.name}</span>
                                <span className='mr-6'>{data.range}</span>
                            </div>

                            <div className="w-2/4 flex justify-end items-center">
                                <button style={{ backgroundColor: data.color }} className={`w-full flex justify-center items-center text-[10px] font-semibold not-italic leading-normal p-2 rounded-md   mx-3 text-[#FFF]`}>
                                    {data.btndata}
                                </button>
                            </div>

                        </div>
                    ))
                }
            </div> </div>
        </div >
    )
}

export default CalculationMethod
