import { faCircle, faLocationPin, faSquare } from '@fortawesome/pro-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWater,  } from "@fortawesome/pro-solid-svg-icons"
import { faArrowUpFromWaterPump, faTankWater, faSquareRing, faPipe, faWater, faArrowUpFromGroundWater, faPipeSection, faHouseFloodWaterCircleArrowRight, faPipeValve, faHouseWater, faPlus, faHexagon } from "@fortawesome/pro-solid-svg-icons"
import { Tooltip } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import "../Map/network.css"
import MapviewPopup from '../../../reusable/MapviewPopup';
// const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
//   c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
//   C20.1,15.8,20.2,15.8,20.2,15.7z`;
// const pinStyle = {
//   fill: '#d00',
//   stroke: 'none'
// }; faHouseFloodWaterCircleArrowRight
const Element = [
  { name: "Source of Intake", icon: faWater },
  { name: "Intake Structure", icon: faArrowUpFromGroundWater },
  { name: "Pumping Station", icon: faArrowUpFromWaterPump },
  { name: "Rising / Gravity Main", icon: faPipeSection },
  { name: "Treatment Facility", icon: faHouseFloodWaterCircleArrowRight },
  { name: "Storage Reservoirs", icon: faTankWater },
  { name: "Chambers", icon: faSquareRing },
  { name: "Valves", icon: faPipeValve },
  { name: "Pipes", icon: faPipe },
  { name: "Water Quality Station", icon: faHouseWater },
  { name: "Mains", icon: faWater, },
  { name: "River Basin", icon: faWater },
]




function Pin(props: any) {


  return (<>

    <Popoverr values={props} />
  </>
  );
}



const Popoverr = ({ values }: any) => {
  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const [, setPopoverPosition] = React.useState({ x: 0, y: 0 });
  let { componentTab } = useAppSelector((state) => state.components);
  const navigate = useNavigate();

  const navigateToSourceOfIntake = () => {
    navigate('/SourceOfIntake');
  };
  const navigateToWaterQualityStation = () => {
    navigate('/WaterQualityStation');
  };

  const navigateToStorageReservior = () => {
    navigate('/StorageReservior');
  };

  const navigateToTreatmentFacility = () => {
    navigate('/ComponentSelection');
  };

  // const navigateToComponentSelection = () => {
  //   navigate('/ComponentSelection');
  // };


  const iconsarray = () => {
    let returnData
    Element.filter((ele: any) => {
      if (ele.name == values?.icon?.name) {
        returnData = (
          values.dark ?
            <FontAwesomeIcon icon={ele.icon ? ele.icon : faWater} style={{ color: "white" }} className='-mt-[2.3rem] h-6 w-5' /> :
            <FontAwesomeIcon icon={ele.icon ? ele.icon : faWater} style={{ color: "white" }} className='-mt-10 h-6 w-5' />
        )
      }
    })
    return returnData
    //return <FontAwesomeIcon icon={icon == faHouseFloodWaterCircleArrowRight ?faHouseFloodWaterCircleArrowRight:faHouseFloodWaterCircleArrowRight} style={{ color: "white" }} className='-mt-9 h-4 w-4' />
  }

  const handleContextMenu = (e: any) => {
    if (componentTab == "checklist") {
      e.preventDefault(); // Prevent default right-click behavior

      setPopoverVisible(true);
      setPopoverPosition({ x: e.clientX, y: e.clientY });
    }
  };



  const content = (
    <div className={` text-sm text-gray-500 dark:text-gray-400 bg-white`}>
      <div className=" flex  text-[#677788] text-sm not-italic font-normal leading-4 justify-between p-2 w-56">
        {/* navigation(`/map`, { state: { projectData: projectdata} }) */}
        <div
          onClick={() => {
            if (values?.icon?.name === "Source of Intake") {
              navigateToSourceOfIntake();
            }
            else if (values?.icon?.name === "Storage Reservoirs") {
              navigateToStorageReservior();
            }
            else if (values?.icon?.name === "Treatment Facility") {
              navigateToTreatmentFacility();
            } else if (values?.icon?.name === "Water Quality Station") {
              navigateToWaterQualityStation();
            }

            else {
              navigate('/map')
            }
          }}
        >
          {/* <Link to="/SourceOfIntake" > */}
          Add Water Quality Checklist
          {/* </Link> */}
        </div>
        <div><FontAwesomeIcon icon={faPlus} /></div>
        <hr />
      </div>
    </div>
  );

  const TooltipConent = (
    <div className={`w-40 text-sm text-gray-500 dark:text-gray-400 `}>

      <div className="py-2 flex flex-col text-[#677788] text-sm not-italic font-normal leading-4">
        <h2 className='font-bold'>{values.icon.name}</h2>
        <hr />
        <h2 className='font-bold'>WQ Station ID</h2>
        <span className='text-[12px]'>{values.icon.element_id}</span>

        <h2 className='font-bold'>Latitude/Longitude</h2>
        <span className='text-[8px]'>{values.icon.latitude}
          <span className='text-[14px]'>/</span>
          {values.icon.longitude}</span>
      </div>
    </div>
  );

  const mapviewTooltipConent = (
    <MapviewPopup key={values.icon} />
  );

  React.useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (popoverVisible && !e.target.closest('.popover')) {
        setPopoverVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popoverVisible]);

  const renderIcon = (componentName: any, raiseAnAlert: boolean) => {
    const baseStyle = 'mb-[3px] w-8 h-11';
    const defaultColor = '#FF8700'; // Orange color
    const alertColor = '#F12E52'; // Red color

    const iconColor = raiseAnAlert === true ? alertColor : defaultColor;

    switch (componentName) {
      case 'Source of Intake':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Treatment Facility':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Storage Reservoirs':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Water Quality Station':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Intake Structure':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Chambers':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Valves':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Rising / Gravity Main':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Pumping Station':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      case 'Pipes':
        return <FontAwesomeIcon className={baseStyle} icon={faCircle} size='2xl' style={{ color: iconColor }} />;
      default:
        return null;
    }
  };
  const markerStyle = {
    width: 50,
    top: 6,
    position: "absolute",
    height: 50,
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 100, 100, 0.8)',
    border: "2px solid rgba(255, 100, 100, 0.4)",
    animation: 'pulse 1s infinite', // Apply the pulse animation
  };
  const oldMapstayle = {

  }
  return (
    <div onClick={(event) => { values.handleNameClick(event, values.icon) }}
      onContextMenu={handleContextMenu}
    >
      {popoverVisible && (
        <div className="popover text-black -ml-20 " >
          {/* Popover content */}
          {content}


        </div>
      )}
      {
        <Tooltip
          placement={values.dark ? 'right' : (popoverVisible ? 'bottom' : 'top')}
          content={values.dark ? mapviewTooltipConent : TooltipConent}
          arrow={values.dark ? false : true}
          style={values.dark ? 'auto' : 'light'}
          className={values.dark ? 'w-[359px]  h-[540px] mt-14 bg-[#222230] dark:bg-[#222230] border-[#222230] cursor-default' : ''}
        >

          {/* <Tooltip placement={popoverVisible ? 'bottom' : 'top'} content={TooltipConent} style='light' onContextMenu={handleContextMenu}> */}

          {
            popoverVisible
          }
          <div style={
            values.dark ?
              (
                (values.icon.name === 'Source of Intake' ||
                  values.icon.name === 'Treatment Facility' ||
                  values.icon.name === 'Storage Reservoirs' ||
                  values.icon.name === 'Water Quality Station' ||
                  values.icon.name === 'Intake Structure' ||
                  values.icon.name === 'Pumping Station' ||
                  values.icon.name === 'Rising / Gravity Main' ||
                  values.icon.name === 'Chambers' ||
                  values.icon.name === 'Valves' ||
                  values.icon.name === 'Pipes'
                ) &&
                  values.icon.RaiseAnAlert === true && values.icon.DesignerChecklistStatus === true
                  ? markerStyle
                  : oldMapstayle
              )
              : oldMapstayle
          }></div>
          <div className='grid justify-items-center  p-2 top-0 bg-transparent ' >
            {
              values.dark ?
                renderIcon(values.icon.name, values.icon.RaiseAnAlert)
                : <FontAwesomeIcon className='mb-[3px] w-8 h-11' icon={faLocationPin} size='2xl' style={{
                  color: `${componentTab == "mapping" ? '#377DFF'
                    : values.icon.name === 'Source of Intake' ||
                      values.icon.name === 'Treatment Facility' ||
                      values.icon.name === 'Storage Reservoirs' ||
                      values.icon.name === 'Water Quality Station' ||
                      values.icon.name === 'Intake Structure' ||
                      values.icon.name === 'Pumping Station' ||
                      values.icon.name === 'Rising / Gravity Main' ||
                      values.icon.name === 'Chambers' ||
                      values.icon.name === 'Valves' ||
                      values.icon.name === 'Pipes'
                      ? (values.icon.DesignerChecklistStatus === true ? "#1AAF55" : "#377DFF")
                      : "#377DFF"
                    }`
                }} />
            }
            {/* <FontAwesomeIcon className='mb-[3px] w-8 h-11' icon={faLocationPin} size='2xl' style={{ color: "#377DFF", }}/> */}
            {
              values.dark ?
                (
                  values.icon.name === 'Source of Intake' ||
                    values.icon.name === 'Treatment Facility' ||
                    values.icon.name === 'Storage Reservoirs' ||
                    values.icon.name === 'Water Quality Station' ||
                    values.icon.name === 'Intake Structure' ||
                    values.icon.name === 'Pumping Station' ||
                    values.icon.name === 'Rising / Gravity Main' ||
                    values.icon.name === 'Chambers' ||
                    values.icon.name === 'Valves' ||
                    values.icon.name === 'Pipes'
                    ?
                    iconsarray() : <></>) :
                iconsarray()
            }
            {values.dark ?
              <></> :

              <FontAwesomeIcon className='text-blue-700 rounded-full border-[3px] border-solid border-white drop-shadow-2xl' style={{
                color: `${componentTab === "mapping"
                  ? '#377DFF'
                  : values.icon.name === 'Source of Intake' ||
                    values.icon.name === 'Treatment Facility' ||
                    values.icon.name === 'Storage Reservoirs' ||
                    values.icon.name === 'Water Quality Station' ||
                    values.icon.name === 'Intake Structure' ||
                    values.icon.name === 'Pumping Station' ||
                    values.icon.name === 'Rising / Gravity Main' ||
                    values.icon.name === 'Chambers' ||
                    values.icon.name === 'Valves' ||
                    values.icon.name === 'Pipes'
                    ? (values.icon.DesignerChecklistStatus === true ? "#1AAF55" : "#377DFF")
                    : "#377DFF"
                  }`
              }} size="sm" icon={faCircle}

              />
            }
          </div>


        </Tooltip>
      }
    </div>
  );
}
export default React.memo(Pin);

