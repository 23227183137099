import Loadable from "react-loadable";
import Loading from "./views/pages/Loading";

export const LoginComponent = Loadable({
  loader: () => import("./views/Login"),
  loading: Loading,
});

export const DashboardComponent = Loadable({
  loader: () => import("./views/pages/Dashboard"),
  loading: Loading,
});

export const UserManagementComponent = Loadable({
  loader: () => import("./views/pages/UserManagement"),
  loading: Loading,
});

export const RoleManagementComponent = Loadable({
  loader: () => import("./views/pages/RoleManagement"),
  loading: Loading,
});

export const AssignPermissionComponent = Loadable({
  loader: () => import("./views/pages/AssignPermission"),
  loading: Loading,
});
export const ProjectManagementComponent = Loadable({
  loader: () => import("./views/pages/ProjectManagment"),
  loading: Loading,
});

export const SourceOfIntakeComponent = Loadable({
  loader: () => import("./views/pages/SourceOfIntake"),
  loading: Loading,
});
export const ChecklistComponent = Loadable({
  loader: () => import("./views/pages/Checklist"),
  loading: Loading,
});
export const StorageReserviorComponent = Loadable({
  loader: () => import("./views/pages/StorageReservior"),
  loading: Loading,
});
export const TreatmentFacilityComponent = Loadable({
  loader: () => import("./views/pages/TreatmentFacility"),
  loading: Loading,
});
export const ComponentSelectionComponent = Loadable({
  loader: () => import("./views/pages/ComponentSelection"),
  loading: Loading,
});
export const MapComponent = Loadable({
  loader: () => import("./views/pages/Map"),
  loading: Loading,
});
export const TokenComponent = Loadable({
  loader: () => import("./views/pages/Token"),
  loading: Loading,
});
export const ScheduleComponent = Loadable({
  loader: () => import("./views/pages/Schedule"),
  loading: Loading,
});
export const SourceOfIntakeTestComponent = Loadable({
  loader: () => import("./views/pages/SourceOfIntakeTest"),
  loading: Loading,
});

export const TestCategoryComponent = Loadable({
  loader: () => import("./views/pages/TestCategory"),
  loading: Loading,
});

export const CalenderComponent = Loadable({
  loader: () => import("./views/pages/Calender"),
  loading: Loading,
});
export const TestSummaryComponent = Loadable({
  loader: () => import("./views/pages/TestSummary"),
  loading: Loading,
});
// export const ProjectComponent = Loadable({
//     loader: () => import("./views/pages/Project"),
//     loading: Loading,
// });
export const ProjectBasicDetailsComponent = Loadable({
  loader: () => import("./views/pages/ProjectBasicDetails"),
  loading: Loading,
});
export const ScreenMonitoringComponent = Loadable({
  loader: () => import("./views/pages/CreateProject"),
  loading: Loading,
});

export const WaterQualityStationComponent = Loadable({
  loader: () => import("./views/pages/WaterQualityStation"),
  loading: Loading,
});
export const AlertsSolutionComponent = Loadable({
  loader: () => import("./views/pages/CreateProject"),
  loading: Loading,
});
export const AlertsSolutionHubComponent = Loadable({
  loader: () => import("./views/pages/AlertsSolutionHub"),
  loading: Loading,
});
export const AquaGPTComponent = Loadable({
  loader: () => import("./views/pages/AquaGPT"),
  loading: Loading,
});
export const GPTThreadComponent = Loadable({
  loader: () => import("./views/pages/GPTThread"),
  loading: Loading,
});
export const BrochureComponent = Loadable({
  loader: () => import("./views/pages/Brochure"),
  loading: Loading,
});
export const MapCom = Loadable({
  loader: () => import("./views/pages/MapComponent"),
  loading: Loading,
});

