import { useEffect, useRef, useState } from 'react';
import img1 from '../../assets/images/dashboard-image.png'
import img2 from '../../assets/images/dashboard-image2.png'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getObservationImages } from '../../redux/slice/monitoring';
import axios from 'axios';

const DashboardImages = () => {

  const dispatch = useAppDispatch();
  const monitoring = useAppSelector((state) => state.monitoring);
  
  const [data, setData] = useState<any>([]);
 // const monitoringData = monitoring?.updatedData;

  const hasFetched = useRef(false); // Ref to track if the API call has been made

  useEffect(() => {
    if (!hasFetched.current && monitoring?.updatedData.schedule_time && monitoring?.updatedData.schedule_date && monitoring?.updatedData?.coordinate_id && monitoring?.updateCategoryId) {
      let data = {
        schedule_time: monitoring?.updatedData.schedule_time,
        schedule_date: monitoring?.updatedData.schedule_date,
        coordinate_id: monitoring?.updatedData?.coordinate_id,
        category_id: monitoring?.updateCategoryId,
      };

      if (data.schedule_date && data.schedule_time && data.coordinate_id && data.category_id) {
        dispatch(getObservationImages(data));
        hasFetched.current = true; // Mark that the API call has been made
      }
    }
  }, [monitoring?.updatedData, monitoring?.updateCategoryId, dispatch]);

  // useEffect(()=>{
  //   if(monitoring.visualObservation.data.data){
  //     const updatedData = monitoring.visualObservation.data.data.map((item:any, idx:any) => {

  //       axios.get(`https://api.weatherapi.com/v1/current.json?key=6e6263afb84f44279f731543222510&q=${item.latitude_longitude}&aqi=no`).then((row:any) => {
  //         console.log("useAppSelector",row.data.location.name);
          
  //         return {
  //           ...item,
  //           location: row.data.location.name,
  //         };
        
  //     })
       
  //         // Update the latitude_longitude for the matched index
  //         // return {
  //         //   ...item,
  //         //   location: `${latitude},${longitude}`,
  //         // };
        
  //     });
  //     console.log("useAppSelector",updatedData,monitoring.visualObservation.data.data);
    
  //     setData(updatedData);
  //   }
    
    
  // },[monitoring.visualObservation.data.data])

  useEffect(() => {
    if (monitoring.visualObservation.data.data) {
      const fetchLocationData = async () => {
        const updatedData = await Promise.all(
          monitoring.visualObservation.data.data.map(async (item:any) => {
            try {
              const response = await axios.get(
                `https://api.weatherapi.com/v1/current.json?key=6e6263afb84f44279f731543222510&q=${item.latitude_longitude}&aqi=no`
              );
              console.log("useAppSelector", response.data.location.name);
              
              return {
                ...item,
                location: response.data.location.name,
              };
            } catch (error) {
              console.error("Error fetching location data:", error);
              return item; // Return the original item if the request fails
            }
          })
        );
  
        setData(updatedData);
      };
  
      fetchLocationData();
    }
  }, [monitoring.visualObservation.data.data]);
  
  
  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full dashboard-img-section flex flex-wrap mt-3">
      {
        data && data.map((data:any) => (
          <div className="w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 relative p-2">
            <img src={data.file_url} alt="" className='w-full 2xl:w-full h-[163px]'/>
            <div className="absolute bottom-5 left-6 image-custom-position">
              <div className='text-[#FFF] font-[Poppins] text-[11px] not-italic font-bold leading-normal'>{data.sampling_date}</div>
              <div className='text-[#FFF] font-[Inter] text-sm not-italic font-medium leading-normal'>{data.location}</div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default DashboardImages
