import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "../../assets/css/reusable/newdashboard.css";
import DashboardChecklistGraph from "../DashboardChecklistGraph";
import ParameterComparision from "../ParameterComparision";
import DashboardImages from "../DashboardImages";
import AlertNotification from "../AlertNotification";
import DashboardGraphs from "../DashboardGraphs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchChecklistCategory } from "../../redux/slice/checklist";
import Slider from "react-slick";
import coordinate, { fetchCoordinates } from "../../redux/slice/coordinate";
import { useLocation } from "react-router-dom";
import {
  monitoringScreenWaterStrech,
  updateCategory,
  updateMonitoring,
} from "../../redux/slice/monitoring";
interface FormData {
  component: any;
}
const NewDashboard = ({ activeTab }: any) => {
  const [formData, setFormData] = useState<FormData>({
    component: "",
  });
  const dispatch = useAppDispatch();
  const { checklistCategory } = useAppSelector((state) => state.checklist);
  const [data, setData]: any = useState();
  const [dashboardTab, setDashboardTab] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const location = useLocation();
  const coordinate = useAppSelector((state) => state.coordinate);
  const monitoring = useAppSelector((state) => state.monitoring);
  const [coordinateName, setCoordinateName] = useState([]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8, // Number of tabs visible at a time
    slidesToScroll: 1,
    arrows: true, // Optional: for showing arrows
  };
  const coordinateData = coordinate?.coordinates?.data?.data;

  const onSelectChangeChange = async (e: any) => {
    const { name, value } = e.target;
    console.log("coordinateData123", coordinateData);
    console.log("Selected Value:", value);
    const item = coordinateData.find(
      (entry: any) => `${entry.name} - ${entry?.element_id}` === value
    );
    console.log("Found Item:", item.id);
    dispatch(
      updateMonitoring({
        ...monitoring.updatedData,
        coordinate_id: item?.id,
      })
    );
    setFormData({ ...formData, [name]: value });
    setStateMonitoringData((prevState: any) => ({
      ...prevState,
      coordinate_id: item?.id,
    }));
  };

  console.log("formData", formData);
  // const coordinateData = coordinate.coordinates.data?.data;

  const [stateMonitoringData, setStateMonitoringData]: any = useState({
    schedule_date: new Date().toISOString().split("T")[0],
    // Format as YYYY-MM-DD
    schedule_time: "23:55:00",
    coordinate_id: null, // Initialize with null or undefined
    category_id: 1,
  });

  // Use useEffect to set the first coordinate ID when coordinateData is available
  useEffect(() => {
    if (coordinateData && coordinateData.length > 0) {
      dispatch(
        updateMonitoring({
          ...monitoring.updatedData,
          coordinate_id: coordinateData[0].id, // Set to the first coordinate ID
        })
      );
    }
  }, [coordinateData]);

  const options = [
    { value: "SOI" },
    { value: "TF" },
    { value: "SR" },
    { value: "IS" },
  ];
  useEffect(() => {
    dispatch(fetchChecklistCategory());
  }, []);

  useEffect(() => {
    // console.log(checklistCategory.data?.data);

    if (checklistCategory?.data?.data) {
      // let checklistAdded: any = checklistCategory.data?.data
      // if (checklistCategory.data.data) {
      //     let ss = checklistCategory.data.data.concat(checklistAdded);
      setData(checklistCategory?.data?.data);
      // }
    }
  }, [checklistCategory?.data?.data]);

  const categoryData = checklistCategory?.data?.data;
  const categoryName = checklistCategory?.data?.data?.map((item: any) => ({
    name: item.name,
    id: item?.id,
  }));
  console.log("categoryData", categoryData);
  console.log("categoryName", categoryName);

  const handleTabClick = (index: any, tab: any) => {
    console.log("index", index, tab?.id);
    setActiveTabIndex(index);
    dispatch(updateCategory(tab?.id));
  };
  useEffect(() => {
    // Dispatch the action with the stateMonitoringData
    if (stateMonitoringData) {
      // dispatch(updateMonitoring(stateMonitoringData));
      dispatch(updateCategory(stateMonitoringData.category_id));
    }
  }, [stateMonitoringData, stateMonitoringData.category_id]);
  // const categoryId = categoryName?.find((entry: { name: string; id: any }) => entry.name === 'value');

  // console.log('itemid',categoryId.id)
  // const tabId = categoryData.find((entry:any) => entry.name === categoryName)?.id
  // console.log('tabId',tabId)
  //  ******************** fetch coordinate starts here ********************
  useEffect(() => {
    dispatch(fetchCoordinates(location.state.projectData.project_id));
  }, []);
  console.log("coordinate", coordinate.coordinates.data?.data);

  // *******************fetch coordinate ends here ********************

  //  ******************** fetch schedule date starts here ********************
  useEffect(() => {
    if (coordinateData?.length > 0) {
      dispatch(
        updateMonitoring({
          ...monitoring.updatedData,
          coordinate_id: coordinateData[0]?.id,
          // Set to the first coordinate ID
        })
      );
    }
    console.log("dataadgdzgaaaaaaaaa", monitoring?.updatedData);
  }, [coordinateData]);

  useEffect(() => {
    let data = {
      ...monitoring?.updatedData,
      project_id: location?.state?.projectData?.project_id,
    };
    // if (data?.project_id && data?.schedule_date) {
    //     dispatch(monitoringScreenWaterStrech(data))
    // }
    console.log("upadtedatagafafaf", data);
  }, [monitoring?.updatedData]);

  console.log("monitoringupdatedData", monitoring?.updatedData);
  const coordinate_id = coordinateData?.map((item: any) => item?.id);

  console.log("coordinateIds", coordinate_id);
  const monitoringData = monitoring?.updatedData;
  console.log("monitoringData", monitoringData);

  //  ******************** fetch schedule date ends here ********************
  console.log("activeTab", activeTab);

  console.log("dashboardTab", dashboardTab);
  const category = [
    {
      id: 1,
      name: "General",
      parameterComparision: (
        <ParameterComparision monitoringData={monitoringData} />
      ),
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 2,
      name: "Nutrients",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 3,
      name: "Demand Parameters",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 4,
      name: "Major ions",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 5,
      name: "Location/Regional Specific",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 6,
      name: "Biological Parameters",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 7,
      name: "Heavy Metals",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 8,
      name: "Agricultural/industrial",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
    {
      id: 9,
      name: "Emerging Contaminants",
      parameterComparision: <ParameterComparision />,
      dashboardGraph: <DashboardGraphs />,
      alertNotification: <AlertNotification />,
      dashboardImages: <DashboardImages />,
    },
  ];

  return (
    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full dashboard-main-div">
      <DashboardChecklistGraph />
      <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full flex justify-between items-center mt-4 px-2">
        <div className="w-w-1/3 sm:w-w-1/3 md:w-w-1/3 lg:w-w-1/3 xl:w-w-1/3 2xl:w-w-1/3 flex justify-start">
          <button className="bg-[#377DFF] rounded-lg p-[10px] flex justify-center items-center text-[#FFF] font-[inter] text-sm not-italic font-normal leading-[22px]">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add New Chart
          </button>
        </div>
        <div className="w-1/3 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 flex  justify-end items-center gap-4">
          <div className="flex w-1/2 justify-end  text-[#1E2022] font-[inter] text-xs not-italic font-medium leading-[21px]">
            Select Component
          </div>
          <div className="w-1/2 select-div">
            <select
              key="component"
              id="component"
              name="component"
              // placeholder=""
              autoComplete="component"
              value={formData.component}
              onChange={onSelectChangeChange}
              className={`text-xs not-italic font-medium leading-[21px] placeholder:text-[#132144] text-[#132144] placeholder:text-xs placeholder:not-italic placeholder:font-medium placeholder:leading-[21px] block w-[100%] h-[32px] rounded-md border boredr-[#EFF1F7]   
                        shadow-sm ring-1 ring-inset focus:ring-1 appearance-none sm:text-xs sm:leading-6 ring-[#FFF] bg-[#FFF] select-field `}
            >
              {/* <option className="capitalize w-[100%] text-[10px] not-italic font-medium leading-[21px]">Select</option> */}
              {coordinateData?.map((opt: any) => (
                <>
                  <option className="capitalize w-[100%] text-[10px] not-italic font-medium leading-[21px]">
                    {opt.name} - {opt?.element_id}
                  </option>
                </>
              ))}
            </select>
          </div>
        </div>
      </div>
      <Tabs selectedIndex={dashboardTab}>
        <TabList className={``}>
          <div className="main-border flex justify-center">
            <Slider {...settings} className="px-2">
              {data &&
                data?.map((tab: any, index: number) => (
                  <Tab
                    key={index}
                    onClick={() => {
                      handleTabClick(index, tab);
                      setDashboardTab(index);
                    }}
                    value={index}
                    className={`tab-button ${
                      index === dashboardTab
                        ? "react-tabs__tab--selected border-0"
                        : "react-tabs__tab"
                    }`}
                  >
                    {tab.name}
                  </Tab>
                ))}
            </Slider>
          </div>
        </TabList>
        {category?.map((data, index) => (
          <TabPanel key={index} value={dashboardTab}>
            <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full py-3">
              <div className="flex w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-2 py-2">
                <div className="w-9/12 sm:w-full md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 pr-1">
                  {/* <ParameterComparision />
                   */}
                  <ParameterComparision monitoringData={monitoringData} />
                  <DashboardGraphs monitoringData={monitoringData} />
                </div>
                <div className="w-3/12 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 pl-[10px]">
                  {/* <AlertNotification />
                   */}
                  <AlertNotification />
                  <DashboardImages />
                </div>
              </div>
            </div>
          </TabPanel>
        ))}
        {/* <TabPanel>
                    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full py-3">
                     
                        <div className="flex w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-2 py-2">
                            <div className="w-9/12 sm:w-full md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 pr-1">
                                <ParameterComparision />
                                <DashboardGraphs />
                            </div>
                            <div className="w-3/12 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 pl-[10px]">
                                <AlertNotification />
                                <DashboardImages />
                            </div>
                        </div>

                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full py-3">
                    
                        <div className="flex w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-2 py-2">
                            <div className="w-9/12 sm:w-full md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 pr-1">
                                <ParameterComparision />
                                <DashboardGraphs />
                            </div>
                            <div className="w-3/12 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 pl-[10px]">
                                <AlertNotification />
                                <DashboardImages />
                            </div>
                        </div>

                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full py-3">
                
                        <div className="flex w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-2 py-2">
                            <div className="w-9/12 sm:w-full md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 pr-1">
                                <ParameterComparision />
                                <DashboardGraphs />
                            </div>
                            <div className="w-3/12 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 pl-[10px]">
                                <AlertNotification />
                                <DashboardImages />
                            </div>
                        </div>

                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full py-3">
      
                        <div className="flex w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-2 py-2">
                            <div className="w-9/12 sm:w-full md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 pr-1">
                                <ParameterComparision />
                                <DashboardGraphs />
                            </div>
                            <div className="w-3/12 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 pl-[10px]">
                                <AlertNotification />
                                <DashboardImages />
                            </div>
                        </div>

                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full py-3">
                     
                        <div className="flex w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-2 py-2">
                            <div className="w-9/12 sm:w-full md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 pr-1">
                                <ParameterComparision />
                                <DashboardGraphs />
                            </div>
                            <div className="w-3/12 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 pl-[10px]">
                                <AlertNotification />
                                <DashboardImages />
                            </div>
                        </div>

                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full py-3">
                     
                        <div className="flex w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-2 py-2">
                            <div className="w-9/12 sm:w-full md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 pr-1">
                                <ParameterComparision />
                                <DashboardGraphs />
                            </div>
                            <div className="w-3/12 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 pl-[10px]">
                                <AlertNotification />
                                <DashboardImages />
                            </div>
                        </div>

                    </div>
                </TabPanel> */}
      </Tabs>
    </div>
  );
};

export default NewDashboard;
